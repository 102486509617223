import React from "react";

export const CategoryDoubleBlockIcon = () => {
  return (
    <svg
      width="177"
      height="222"
      viewBox="0 0 177 222"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="category-double-icon"
    >
      <path
        d="M51.3033 165.818C51.2602 166.012 51.2586 166.207 51.317 166.374C51.4713 166.807 51.9332 166.896 52.3538 166.574C82.6939 143.409 110.222 115.671 134.193 84.1265C134.536 83.675 134.58 83.0707 134.295 82.7744C134.003 82.4815 133.494 82.6105 133.15 83.0633C109.329 114.413 81.962 141.983 51.8082 165.007C51.5506 165.205 51.3695 165.513 51.3018 165.821L51.3033 165.818Z"
        fill="#151515"
      />
      <path
        d="M24.9924 184.283C24.9569 184.447 24.9519 184.61 24.9885 184.758C25.0966 185.222 25.5402 185.367 25.9737 185.09C30.158 182.399 34.3585 179.581 38.4664 176.715C38.8948 176.415 39.1425 175.806 39.0154 175.358C38.882 174.91 38.4337 174.79 37.9998 175.088C33.9198 177.938 29.7385 180.741 25.5785 183.415C25.2838 183.603 25.0674 183.942 24.9922 184.281L24.9924 184.283Z"
        fill="#151515"
      />
      <path
        d="M0.355878 178.284C0.326828 178.416 0.319702 178.551 0.338065 178.68C0.404451 179.166 0.820266 179.37 1.26352 179.134C40.6779 158.256 76.5026 129.376 107.76 93.2938C108.127 92.869 108.221 92.2531 107.962 91.9218C107.709 91.5879 107.199 91.6628 106.832 92.0889C75.7823 127.934 40.1831 156.626 1.0201 177.374C0.691494 177.547 0.440898 177.911 0.358548 178.284L0.357592 178.283L0.355878 178.284Z"
        fill="#151515"
      />
      <path
        d="M164.66 46.5169C164.607 46.7581 164.625 46.9981 164.728 47.1809C164.935 47.5605 165.428 47.5598 165.827 47.1784L171.397 41.8059C171.79 41.426 171.945 40.8043 171.731 40.423C171.519 40.0422 171.03 40.0436 170.631 40.4255L165.062 45.798C164.858 45.9955 164.717 46.2579 164.66 46.5169Z"
        fill="#151515"
      />
      <path
        d="M151.686 59.0353C151.633 59.2766 151.651 59.5147 151.748 59.6982C151.961 60.079 152.454 60.0788 152.848 59.6956L158.417 54.3245C158.816 53.9412 158.965 53.3215 158.758 52.9401C158.545 52.5593 158.051 52.5594 157.658 52.9426L152.089 58.3157C151.885 58.5132 151.745 58.7755 151.688 59.0345L151.686 59.0353Z"
        fill="#151515"
      />
      <path
        d="M162.921 53.6485C162.869 53.8885 162.887 54.1284 162.989 54.3126L165.95 59.666C166.158 60.0475 166.65 60.0468 167.049 59.6649C167.443 59.2803 167.592 58.6625 167.385 58.2811L164.423 52.9258C164.217 52.5449 163.723 52.5451 163.325 52.9289C163.121 53.1264 162.979 53.3882 162.921 53.6485Z"
        fill="#151515"
      />
      <path
        d="M156.035 41.174C155.981 41.4153 156.001 41.6525 156.103 41.8349L159.057 47.1897C159.271 47.5711 159.757 47.5705 160.157 47.1872C160.556 46.8053 160.704 46.1875 160.491 45.8062L157.537 40.4513C157.323 40.07 156.83 40.0706 156.437 40.4519C156.232 40.6508 156.091 40.9146 156.034 41.1735L156.035 41.174Z"
        fill="#151515"
      />
    </svg>
  );
};

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import theme from "../../../../theme";

export const WhyWeTheBest = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  margin: "0px 0px 80px",

  [theme.breakpoints.down("xl")]: {
    margin: "70px 0px 80px",
  },

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    margin: "60px 0",
  },
});

export const WhyWeTheBestTitle = styled(Typography)({
  maxWidth: "396px",
  width: "100%",
  padding: "24px 0",
  fontWeight: 600,
  textTransform: "uppercase",
  letterSpacing: "-0.02em",

  [theme.breakpoints.down("lg")]: {
    maxWidth: "100%",
    paddingTop: 0,
    fontSize: "34px",
    lineHeight: "42px",
  },
});

export const WhyWeTheBestArrow = styled(Box)({
  [theme.breakpoints.down("xl")]: {
    display: "none",
  },
});

export const WhyWeTheBestContent = styled(Box)({
  display: "flex",
  alignItems: "center",

  "@media (max-width: 375px)": {
    justifyContent: "space-between",
    width: "100%",
  },
});

export const WhyWeTheBestItem = styled(Box)({
  display: "flex",
  flexDirection: "column",

  a: {
    "&:nth-of-type(1), &:nth-of-type(1) img": {
      width: "208px",
      height: "93px",
    },
    "&:nth-of-type(2), &:nth-of-type(2) img": {
      width: "155px",
      height: "54px",
    },
  },

  "&:last-child": {
    marginLeft: "50px",
  },

  [theme.breakpoints.down("xl")]: {
    a: {
      "&:nth-of-type(1), &:nth-of-type(1) img, &:nth-of-type(2), &:nth-of-type(2) img":
        {
          width: "130px",
          height: "auto",
        },

      "& img": {
        width: "100%",
        height: "auto",
      },
    },

    "&:last-child": {
      marginLeft: 40,

      a: {
        width: "174px",
      },
    },
  },

  "@media (max-width: 375px)": {
    "&:last-child": {
      marginLeft: 0,

      a: {
        width: "120px",
        "&:nth-of-type(2), &:nth-of-type(2) img": {
          width: "155px",
          height: "54px",
        },
      },
    },
  },
});

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { motion } from "framer-motion";

import theme from "../../../../theme";

export const OurTeamWrapper = styled(Box)({
    width: '100%',
    display: 'block',

    '@media (min-width: 1201px) and (min-height: 753px)': {
        margin: '140px auto 40px',
    },
    '@media (min-width: 1201px) and (max-height: 853px)': {
        margin: '77px auto 10px',
    },
    [theme.breakpoints.down('xl')]: {
        margin: '48px auto 64px',
    },
    [theme.breakpoints.down('lg')]: {
        marginTop: 0,
    },
});

export const OurTeamInner = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '5px',

    [theme.breakpoints.down('xl')]: {
        marginTop: '48px',
    },
    [theme.breakpoints.down('md')]: {
        marginTop: 0,
    },
});

export const OurTeamContent = styled(motion.div)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('lg')]: {
        flexWrap: 'wrap',
    },
});

export const OurTeamItem = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '180px',
    width: '100%',

    [theme.breakpoints.down('lg')]: {
        maxWidth: '153px',

        '& svg': {
            width: '100%',
        },
    },

    [theme.breakpoints.down('xs')]: {
        maxWidth: '143px',
    },
});

export const OurTeamImage = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    height: '124px',

    [theme.breakpoints.down('md')]: {
        marginTop: '24px',
    },
});

export const OurTeamTitle = styled(Typography)({
    marginTop: '18px',
    fontWeight: 600,
    lineHeight: '26px',

    [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
    },
});

import Typography from "@mui/material/Typography";
import React, { useRef } from "react";
import { WhyMalevichItem, WhyMalevichItemWrapper } from "./style";
import TheBiggestArrowIcon from "../../../../assets/icons/TheBiggestArrowIcon";
import { useBreakpointValue } from "../../../../hooks/use-breakpoint-value";

const WhyMalevichItems = ({ item, activeSlideID }) => {
  const isTablet = useBreakpointValue([
    "smallMobile",
    "mobile",
    "bigMobile",
    "tablet",
  ]);

  const reference = useRef();

  const WrapperComponent = isTablet
    ? WhyMalevichItemWrapper
    : ({ children }) => (
        <WhyMalevichItem
          ref={reference}
          initial={{
            x: !isTablet ? "-50%" : "0%",
            opacity: !isTablet ? 0 : 1,
            scale: !isTablet ? 0 : 1,
          }}
          animate={{
            ...(activeSlideID === "strapi_ComponentLandingWhyMalevich" && {
              x: "0%",
              opacity: 1,
              scale: 1,
            }),
          }}
          transition={{
            type: "tween",
            ease: "easeIn",
            delay: 0.5,
          }}
          viewport={{
            once: true,
            amount: 1,
          }}
        >
          {children}
        </WhyMalevichItem>
      );

  return (
    <WrapperComponent>
      <Typography variant="body2">{item.description}</Typography>
      <TheBiggestArrowIcon />
    </WrapperComponent>
  );
};

export default WhyMalevichItems;

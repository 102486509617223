import Container from "../../../container";
import { TeamBannerContainer, TeamBannerWrapper } from "./style";
import { HOST_IMAGE_URL } from "../../../../constants";
import React from "react";

const LandingTeamBanner = ({ element }) => {
  return (
    <TeamBannerWrapper>
      <Container>
        <TeamBannerContainer>
          <img
            src={`${HOST_IMAGE_URL + element?.image?.data?.attributes?.url}`}
            alt={element?.image?.data?.attributes?.alternativeText}
            title={element?.image?.data?.attributes?.caption}
          />
        </TeamBannerContainer>
      </Container>
    </TeamBannerWrapper>
  );
};

export default LandingTeamBanner;

import Typography from "@mui/material/Typography";

import Container from "../../../container";
import {
  PricesContent,
  PricesDescription,
  PricesItem,
  PricesWrapper,
} from "./style";
import { HOST_IMAGE_URL } from "../../../../constants";
import React from "react";

const LandingPrices = ({ element }) => {
  return (
    <Container>
      <PricesWrapper>
        <Typography variant="h2">{element.title}</Typography>
        <PricesContent>
          {element?.items?.map((item) => {
            return (
              <PricesItem key={item.id}>
                <div className="pricesTop">
                  <img
                    className="icon"
                    src={`${
                      HOST_IMAGE_URL + item?.icon?.data?.attributes?.url
                    }`}
                    alt={item?.icon?.data?.attributes?.alternativeText}
                    title={item?.icon?.data?.attributes?.caption}
                  />
                  <img
                    className="hoverIcon"
                    src={`${
                      HOST_IMAGE_URL + item?.hoverIcon?.data?.attributes?.url
                    }`}
                    alt={item?.hoverIcon?.data?.attributes?.alternativeText}
                    title={item?.hoverIcon?.data?.attributes?.caption}
                  />
                  <div className="price">{item.price}</div>
                </div>
                <div className="line" />
                <div className="text">{item.text}</div>
              </PricesItem>
            );
          })}
        </PricesContent>
        <PricesDescription variant="body1">
          {element.description}
        </PricesDescription>
      </PricesWrapper>
    </Container>
  );
};

export default LandingPrices;

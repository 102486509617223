import Container from "../../../container";
import {
  InfrastructureContent,
  InfrastructureItem,
  InfrastructureItemCheck,
  InfrastructureItemIcon,
  InfrastructureItemTitle,
  InfrastructureTitle,
  InfrastructureWrapper,
} from "./style";
import { HOST_IMAGE_URL } from "../../../../constants";
import React from "react";
import CheckIcon from "../../../../assets/icons/CheckIcon";

const Infrastructure = ({ element }) => {
  return (
    <Container>
      <InfrastructureWrapper>
        <InfrastructureTitle variant="h2">{element.title}</InfrastructureTitle>
        <InfrastructureContent>
          {element?.items?.map((item) => {
            return (
              <InfrastructureItem key={item.id}>
                <InfrastructureItemIcon>
                  <img
                    src={`${
                      HOST_IMAGE_URL + item?.icon?.data?.attributes?.url
                    }`}
                    alt={item?.icon?.data?.attributes?.alternativeText}
                    title={item?.icon?.data?.attributes?.caption}
                  />
                </InfrastructureItemIcon>
                <InfrastructureItemTitle variant="h4">
                  {item.title}
                </InfrastructureItemTitle>
                <InfrastructureItemCheck
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                  }}
                  transition={{
                    type: "tween",
                    delay: 0.3,
                    duration: 1,
                    ease: "easeIn",
                  }}
                  viewport={{
                    once: true,
                    amount: 1,
                  }}
                >
                  <CheckIcon />
                </InfrastructureItemCheck>
              </InfrastructureItem>
            );
          })}
        </InfrastructureContent>
      </InfrastructureWrapper>
    </Container>
  );
};

export default Infrastructure;

import React from "react";
import {
    TechnologyAmazonWebServicesIcon,
    TechnologyCloudflareIcon,
    TechnologyConsulIcon,
    TechnologyDigitalOceanIcon,
    TechnologyDockerIcon,
    TechnologyElasticIcon,
    TechnologyExpressIcon,
    TechnologyFlutterIcon,
    TechnologyGatsbyIcon,
    TechnologyGraphqlIcon,
    TechnologyJavascriptIcon,
    TechnologyJenkinsIcon,
    TechnologyMongodbIcon,
    TechnologyMongooseIcon,
    TechnologyMysqlIcon,
    TechnologyNestIcon,
    TechnologyNextIcon,
    TechnologyNginxIcon,
    TechnologyNodeIcon,
    TechnologyPostgresqlIcon,
    TechnologyRabbitIcon,
    TechnologyReactIcon,
    TechnologyReactNativeIcon,
    TechnologyReduxIcon,
    TechnologySequelizeIcon,
    TechnologyTrafikIcon,
    TechnologyTypescriptIcon,
    TechnologyRedisIcon,
} from '../assets/icons/technologiesList';

export const TECHNOLOGY_STACK_ICONS = [
    {
        id: 1,
        icon: <TechnologyRedisIcon />,
    },
    {
        id: 2,
        icon: <TechnologyReactIcon />,
    },
    {
        id: 3,
        icon: <TechnologyReactNativeIcon />,
    },
    {
        id: 4,
        icon: <TechnologyMongooseIcon />,
    },
    {
        id: 5,
        icon: <TechnologyJenkinsIcon />,
    },
    {
        id: 6,
        icon: <TechnologyNginxIcon />,
    },
    {
        id: 7,
        icon: <TechnologyJavascriptIcon />,
    },
    {
        id: 8,
        icon: <TechnologyRabbitIcon />,
    },
    {
        id: 9,
        icon: <TechnologyTrafikIcon />,
    },
    {
        id: 10,
        icon: <TechnologyDigitalOceanIcon />,
    },
    {
        id: 11,
        icon: <TechnologyNestIcon />,
    },
    {
        id: 12,
        icon: <TechnologyDockerIcon />,
    },
    {
        id: 13,
        icon: <TechnologyGatsbyIcon />,
    },
    {
        id: 14,
        icon: <TechnologyTypescriptIcon />,
    },
    {
        id: 15,
        icon: <TechnologyExpressIcon />,
    },
    {
        id: 16,
        icon: <TechnologyNodeIcon />,
    },
    {
        id: 17,
        icon: <TechnologyElasticIcon />,
    },
    {
        id: 18,
        icon: <TechnologyConsulIcon />,
    },
    {
        id: 19,
        icon: <TechnologyFlutterIcon />,
    },
    {
        id: 20,
        icon: <TechnologyCloudflareIcon />,
    },
    {
        id: 21,
        icon: <TechnologyPostgresqlIcon />,
    },
    {
        id: 22,
        icon: <TechnologyMysqlIcon />,
    },
    {
        id: 23,
        icon: <TechnologyAmazonWebServicesIcon />,
    },
    {
        id: 24,
        icon: <TechnologyMongodbIcon />,
    },
    {
        id: 25,
        icon: <TechnologySequelizeIcon />,
    },
    {
        id: 26,
        icon: <TechnologyNextIcon />,
    },
    {
        id: 27,
        icon: <TechnologyGraphqlIcon />,
    },
    {
        id: 28,
        icon: <TechnologyReduxIcon />,
    },
];

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import theme from "../../../../theme";
import palette from "../../../../theme/palette";

export const WhoWeAre = styled(Box)({
  position: "relative",
  marginTop: "75px",

  "& .title": {
    marginTop: "40px",
  },

  [theme.breakpoints.down("xl")]: {
    marginTop: "16px",
    "& .title": {
      marginTop: "98px",
    },
  },

  [theme.breakpoints.down("lg")]: {
    "& .title": {
      marginTop: "118px",
    },
  },
});

export const WhoWeAreTitle = styled(Typography)({
  marginTop: "60px",

  [theme.breakpoints.down("xl")]: {
    marginTop: "98px",
  },

  [theme.breakpoints.down("lg")]: {
    marginTop: "118px",
  },
});

export const WhoWeAreContent = styled(Box)({
  display: "flex",
  marginTop: "15px",

  [theme.breakpoints.down("xl")]: {
    marginTop: "40px",
  },
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    marginTop: "20px",
  },
});

export const Description = styled(Typography)({
  maxWidth: "680px",
  width: "100%",
  fontWeight: 400,

  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    lineHeight: "22px",

    "& strong": {
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
});

export const CounterBlock = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  maxWidth: "430px",
  width: "100%",
  marginTop: "40px",

  [theme.breakpoints.down("xl")]: {
    marginTop: "80px",
  },

  [theme.breakpoints.down("lg")]: {
    justifyContent: "space-between",
    flexWrap: "wrap",
    maxWidth: "100%",
    margin: "45px -12px 0",
  },
});

export const CounterBlockItem = styled(Box)({
  maxWidth: "100px",
  width: "100%",
  marginRight: "65px",
  marginBottom: "24px",

  "&:nth-of-type(3)": {
    marginRight: 0,
  },

  [theme.breakpoints.down("xl")]: {
    marginBottom: "36px",
  },

  [theme.breakpoints.down("lg")]: {
    margin: "0 12px 36px",
  },
});

export const CounterBlockQuantity = styled(Typography)({
  color: palette.purpleBlue,

  [theme.breakpoints.down("lg")]: {
    fontSize: "48px",
    lineHeight: "72px",
  },
});

export const RightBlock = styled(Box)({
  display: "flex",
  justifyContent: "center",
  maxWidth: "578px",
  width: "100%",
  height: "360px",
  marginLeft: "-78px",

  [theme.breakpoints.down("xl")]: {
    maxWidth: "400px",
  },

  [theme.breakpoints.down("lg")]: {
    maxWidth: "320px",
    height: "200px",
    marginLeft: 0,
  },
});

import React from "react";
const CheckIcon = ({ color = '#24CA49', width = 32, height = 32 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.1337 22.1332L23.5337 12.7332L21.667 10.8665L14.1337 18.3998L10.3337 14.5998L8.46699 16.4665L14.1337 22.1332ZM16.0003 29.3332C14.1559 29.3332 12.4225 28.9829 10.8003 28.2825C9.1781 27.5829 7.76699 26.6332 6.56699 25.4332C5.36699 24.2332 4.41721 22.8221 3.71766 21.1998C3.01721 19.5776 2.66699 17.8443 2.66699 15.9998C2.66699 14.1554 3.01721 12.4221 3.71766 10.7998C4.41721 9.17762 5.36699 7.7665 6.56699 6.5665C7.76699 5.3665 9.1781 4.41628 10.8003 3.71584C12.4225 3.01628 14.1559 2.6665 16.0003 2.6665C17.8448 2.6665 19.5781 3.01628 21.2003 3.71584C22.8225 4.41628 24.2337 5.3665 25.4337 6.5665C26.6337 7.7665 27.5834 9.17762 28.283 10.7998C28.9834 12.4221 29.3337 14.1554 29.3337 15.9998C29.3337 17.8443 28.9834 19.5776 28.283 21.1998C27.5834 22.8221 26.6337 24.2332 25.4337 25.4332C24.2337 26.6332 22.8225 27.5829 21.2003 28.2825C19.5781 28.9829 17.8448 29.3332 16.0003 29.3332Z'
        fill={color}
      />
    </svg>
  );
};

export default CheckIcon;

import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "../../../container";
import {
  CounterBlock,
  CounterBlockItem,
  CounterBlockQuantity,
  Description,
  RightBlock,
  WhoWeAre,
  WhoWeAreContent,
  WhoWeAreTitle,
} from "./style";
import LottieIcon from "../../../lottieIcons";
import malevichAnimation from "../../../../assets/animations/malevichAnimation.json";
import BackgroundTitle from "../../backgroundTitle";

const LandingWhoWeAre = ({ element }) => {
  const logoAnimation = {
    animationData: malevichAnimation,
    loop: 0,
    autoplay: true,
  };

  return (
    <WhoWeAre>
      <BackgroundTitle maxWidth="740px">{element.title}</BackgroundTitle>
      <Container direction="column">
        <WhoWeAreTitle variant="h2">{element.title}</WhoWeAreTitle>
        <WhoWeAreContent>
          <Box>
            <Description variant="body1">{element.description}</Description>
            <CounterBlock>
              {element?.items?.map((item) => {
                return (
                  <CounterBlockItem key={item.id}>
                    <CounterBlockQuantity variant="h2">
                      {item.quantity}
                    </CounterBlockQuantity>
                    <Typography variant="body2">{item.text}</Typography>
                  </CounterBlockItem>
                );
              })}
            </CounterBlock>
          </Box>
          <RightBlock>
            <LottieIcon options={logoAnimation} replayInView disableOnHover />
          </RightBlock>
        </WhoWeAreContent>
      </Container>
    </WhoWeAre>
  );
};

export default LandingWhoWeAre;

import React, { useMemo, useRef } from "react";
import { HOST_IMAGE_URL } from "../../../constants";
import { LandingServicesItem, LandingServicesItemWrapper } from "./style";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useBreakpointValue } from "../../../hooks/use-breakpoint-value";
import styled from "@emotion/styled";
import theme from "../../../theme";

const AdvantagesItems = ({ item, odd, activeSlideID }) => {
  const isTablet = useBreakpointValue([
    "smallMobile",
    "mobile",
    "bigMobile",
    "tablet",
  ]);

  const reference = useRef();

  const positionX = useMemo(() => {
    if (!isTablet) {
      return odd ? "-100%" : "100%";
    } else {
      return "0%";
    }
  }, [isTablet, odd]);

  const WrapperComponent = isTablet
    ? styled(Box)({
        maxWidth: "580px",
        width: "100%",

        [theme.breakpoints.down("xl")]: {
          maxWidth: "100%",
        },
      })
    : ({ children }) => (
        <LandingServicesItemWrapper
          initial={{
            x: positionX,
          }}
          animate={{
            ...(activeSlideID === "strapi_ComponentLandingServices" && {
              x: "0%",
            }),
          }}
          transition={{
            ease: "linear",
            duration: 1.25,
            delay: 0.1,
          }}
          viewport={{
            once: true,
            amount: 1,
          }}
        >
          {children}
        </LandingServicesItemWrapper>
      );

  return (
    <WrapperComponent>
      <LandingServicesItem ref={reference}>
        <Box className="icon">
          <img
            src={`${HOST_IMAGE_URL + item?.icon?.data?.attributes?.url}`}
            alt={item?.icon?.data?.attributes?.alternativeText}
            title={item?.icon?.data?.attributes?.caption}
          />
        </Box>
        <Box className="hoverIcon">
          <img
            src={`${HOST_IMAGE_URL + item?.hoverIcon?.data?.attributes?.url}`}
            alt={item?.hoverIcon?.data?.attributes?.alternativeText}
            title={item?.hoverIcon?.data?.attributes?.caption}
          />
        </Box>
        <Box className="line" />
        <Typography
          variant="body2"
          component="div"
          className="description"
          dangerouslySetInnerHTML={{
            __html: item.description,
          }}
        />
      </LandingServicesItem>
    </WrapperComponent>
  );
};

export default AdvantagesItems;

import React from "react";
export const ArrowLongIcon = ({ color = '#151515', width = 202, height = 8 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 202 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM201.354 4.35357C201.549 4.15831 201.549 3.84173 201.354 3.64646L198.172 0.464483C197.976 0.269221 197.66 0.269221 197.464 0.464483C197.269 0.659745 197.269 0.976328 197.464 1.17159L200.293 4.00002L197.464 6.82844C197.269 7.02371 197.269 7.34029 197.464 7.53555C197.66 7.73081 197.976 7.73081 198.172 7.53555L201.354 4.35357ZM1 4.5L201 4.50002L201 3.50002L1 3.5L1 4.5Z'
        fill={color}
      />
    </svg>
  );
};

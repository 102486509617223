import { StyledButton } from "./styles";
import React from "react";

const Button = ({ title, className, type, disabled, onClick }) => {
  return (
    <StyledButton
      variant={"contained"}
      className={className}
      type={type || "button"}
      disabled={disabled}
      onClick={onClick}
    >
      {title}
    </StyledButton>
  );
};

export default Button;

import React from "react";
import Typography from "@mui/material/Typography";
import Container from "../../container";
import {
  LandingServicesDescription,
  LandingServicesList,
  LandingServicesWrapper,
} from "./style";
import LandingServicesItems from "./LandingServicesItems";

const LandingServices = ({ element, activeSlideID }) => {
  return (
    <LandingServicesWrapper>
      <Container direction="column">
        <Typography variant="h2">{element.title}</Typography>
        <LandingServicesDescription></LandingServicesDescription>
        <Typography
          variant="h4"
          component="div"
          dangerouslySetInnerHTML={{
            __html: element.description,
          }}
        />
        <LandingServicesList>
          {element?.items?.map((item, index) => {
            return (
              <LandingServicesItems
                key={item.id}
                item={item}
                odd={index % 2 === 0}
                activeSlideID={activeSlideID}
              />
            );
          })}
        </LandingServicesList>
      </Container>
    </LandingServicesWrapper>
  );
};

export default LandingServices;

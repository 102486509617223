import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import palette from "../../../theme/palette";
import Typography from "@mui/material/Typography";
import theme from "../../../theme";

export const LandingAboutWrapper = styled(Box)({
  maxWidth: "1300px",
  width: "100%",
  margin: "0 auto",
  padding: "60px",
  color: palette.secondary,
  backgroundColor: palette.primary,
  [theme.breakpoints.down("xl")]: {
    backgroundColor: "transparent",
    color: palette.primary,
    padding: "0px",
    margin: "48px 0px 88px 0px",
  },
  [theme.breakpoints.down("lg")]: {
    h2: {
      fontSize: "32px",
      lineHeight: "48px",
    },
  },
});

export const LandingAboutContent = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  marginTop: "24px",
  [theme.breakpoints.down("lg")]: {
    marginTop: "16px",
    p: {
      lineHeight: "22px",
    },
  },
});

export const LandingAboutDescription = styled(Typography)({
  maxWidth: "420px",
  width: "100%",

  "& p": {
    marginBottom: "24px",

    "&:last-of-type": {
      marginBottom: 0,
    },
  },

  [theme.breakpoints.down("xl")]: {
    maxWidth: "unset",
  },
});

export const LandingAboutItems = styled(Box)({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {
    display: "none",
  },
});

export const LandingAboutImage = styled(Box)({
  position: "relative",
  width: "194px",
  height: "249px",
  marginRight: "36px",
  zIndex: 0,

  "&:last-child": {
    marginRight: "0px",
  },

  "&:hover": {
    zIndex: 1,

    img: {
      position: "absolute",
      right: "18px",
      top: "-44px",
      transform: "rotate(-19deg)",
      transitionDuration: "1s",
    },
  },
});

export const LandingAboutItem = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  padding: "16px",
  backgroundColor: palette.primary,
  border: `1px dashed ${palette.secondary}`,
  borderRadius: "16px",

  "& .cube": {
    width: "32px",
    height: "32px",
    marginBottom: "12px",
    backgroundColor: palette.purpleBlue,
    borderRadius: "8px",
  },

  "& .title": {
    textTransform: "uppercase",
  },

  "& .description": {
    fontSize: "8px",
    lineHeight: "12px",
  },

  "&:nth-of-type(2)": {
    "& .cube": {
      backgroundColor: palette.darkMintGreen,
    },
  },

  "&:last-of-type": {
    marginRight: 0,

    "& .cube": {
      backgroundColor: palette.purpleDaffodil,
    },
  },

  "&:hover": {
    top: "-11px",
    left: "38px",
    color: palette.primary,
    backgroundColor: palette.secondary,
    transform: "rotate(10deg)",
    transitionDuration: "1s",
    zIndex: 1,
  },
});

import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import palette from "../../theme/palette";

export const StyledButton = styled(Button)({
  width: "144px",
  height: "42px",
  padding: 0,
  color: palette.secondary,
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "21px",
  textTransform: "none",
  backgroundColor: palette.primary,
  border: `1px solid ${palette.primary}`,
  borderRadius: 0,
  boxShadow: "none",

  "&:hover": {
    color: palette.primary,
    backgroundColor: palette.secondary,
    boxShadow:
      "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
  },
});

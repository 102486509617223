import React from "react";

import OutWorkSliderContent from "./OutWorkSliderContent";
import { Slider } from "../../../slider";

const OurWorkSlider = ({ element }) => {
  const ourWorkArray = element?.leftColumn.concat(element?.rightColumn);

  return (
    <>
      <Slider
        settings={{
          autoplay: false,
        }}
        isArrowBox
        portalIdNext="ourwork-next-arrow"
        portalIdPrev="ourwork-prev-arrow"
        width="100%"
        margin="0px"
        wrapperProps={{
          marginBottom: "68px",
        }}
      >
        {ourWorkArray?.map((item) => {
          return <OutWorkSliderContent item={item} key={item.id} />;
        })}
      </Slider>
    </>
  );
};

export default OurWorkSlider;

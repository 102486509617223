import React from "react";
import {
  LandingAboutContent,
  LandingAboutDescription,
  LandingAboutImage,
  LandingAboutItem,
  LandingAboutItems,
  LandingAboutWrapper,
} from "./style";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "../../container";
import { HOST_IMAGE_URL } from "../../../constants";

const LandingAbout = ({ element }) => {
  return (
    <LandingAboutWrapper>
      <Container direction="column">
        <Typography variant="h2">{element.title}</Typography>
        <LandingAboutContent>
          <LandingAboutDescription
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: element.description,
            }}
          />
          <LandingAboutItems>
            {element?.items?.map((item) => {
              return (
                <LandingAboutImage key={item.id}>
                  <img
                    src={`${
                      HOST_IMAGE_URL +
                      item?.backgroundImage?.data?.attributes?.url
                    }`}
                    alt={
                      item?.backgroundImage?.data?.attributes?.alternativeText
                    }
                    title={item?.backgroundImage?.data?.attributes?.caption}
                  />
                  <LandingAboutItem>
                    <Box>
                      <Box className="cube" />
                      <Typography variant="body1" className="title">
                        {item.title}
                      </Typography>
                    </Box>
                    <Box variant="overline" className="description">
                      {item.description}
                    </Box>
                  </LandingAboutItem>
                </LandingAboutImage>
              );
            })}
          </LandingAboutItems>
        </LandingAboutContent>
      </Container>
    </LandingAboutWrapper>
  );
};

export default LandingAbout;

import React from "react";
import Container from "../../../container";
import Box from "@mui/material/Box";
import {
  OurTeamContent,
  OurTeamImage,
  OurTeamInner,
  OurTeamItem,
  OurTeamTitle,
  OurTeamWrapper,
} from "./style";
import { HOST_IMAGE_URL } from "../../../../constants";
import Typography from "@mui/material/Typography";
import { useBreakpointValue } from "../../../../hooks/use-breakpoint-value";
import theme from "../../../../theme";
import { styled } from "@mui/material";

const LandingOurTeam = ({ element, activeSlideID }) => {
  const isTablet = useBreakpointValue([
    "smallMobile",
    "mobile",
    "bigMobile",
    "tablet",
  ]);

  const WrapperComponent = isTablet
    ? styled(Box)({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        [theme.breakpoints.down("lg")]: {
          flexWrap: "wrap",
        },
      })
    : ({ children }) => (
        <OurTeamContent
          initial={{
            y: !isTablet ? "-50%" : "0%",
            width: !isTablet ? "400px" : "100%",
            scale: !isTablet ? 0 : 1,
          }}
          animate={{
            ...(activeSlideID === "strapi_ComponentLandingOurTeam" && {
              justifyContent: "space-between",
              y: "0%",
              width: "100%",
              scale: 1,
            }),
          }}
          transition={{
            type: "tween",
            duration: 2,
          }}
          viewport={{
            once: true,
            amount: 1,
          }}
        >
          {children}
        </OurTeamContent>
      );

  return (
    <Container>
      <OurTeamWrapper>
        <Typography variant="h2">{element.title}</Typography>
        <OurTeamInner>
          <WrapperComponent>
            {element?.items?.map((item) => {
              return (
                <OurTeamItem key={item.id}>
                  <OurTeamImage>
                    <img
                      src={`${
                        HOST_IMAGE_URL + item?.image?.data?.attributes?.url
                      }`}
                      alt={item?.image?.data?.attributes?.alternativeText}
                      title={item?.image?.data?.attributes?.caption}
                    />
                  </OurTeamImage>
                  <OurTeamTitle variant="body1">{item.title}</OurTeamTitle>
                </OurTeamItem>
              );
            })}
          </WrapperComponent>
        </OurTeamInner>
      </OurTeamWrapper>
    </Container>
  );
};

export default LandingOurTeam;

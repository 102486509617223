import React from "react";

export const ArrowTechnologyStackIcon = ({
  color = "#151515",
  width = 141,
  height = 9,
}) => {
  return (
    <svg
      width="141"
      height="9"
      viewBox="0 0 141 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M140.354 4.85355C140.549 4.65829 140.549 4.34171 140.354 4.14645L137.172 0.964466C136.976 0.769204 136.66 0.769204 136.464 0.964466C136.269 1.15973 136.269 1.47631 136.464 1.67157L139.293 4.5L136.464 7.32843C136.269 7.52369 136.269 7.84027 136.464 8.03553C136.66 8.2308 136.976 8.2308 137.172 8.03553L140.354 4.85355ZM0 5H140V4H0V5Z"
        fill="#9A9AAF"
      />
    </svg>
  );
};

import React from "react";
import palette from "../../theme/palette";
const TheBiggestArrowIcon = ({ color = palette.amethystSmoke, width = 293, height = 8 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 293 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M292.354 4.35355C292.549 4.15829 292.549 3.84171 292.354 3.64645L289.172 0.464466C288.976 0.269204 288.66 0.269204 288.464 0.464466C288.269 0.659728 288.269 0.976311 288.464 1.17157L291.293 4L288.464 6.82843C288.269 7.02369 288.269 7.34027 288.464 7.53553C288.66 7.7308 288.976 7.7308 289.172 7.53553L292.354 4.35355ZM0 4.5H292V3.5H0V4.5Z'
        fill={color}
      />
    </svg>
  );
};

export default TheBiggestArrowIcon;

import React from "react";
import Container from "../../../container";
import {
    LandingItWorksLikeThisContent, LandingItWorksLikeThisItem, LandingItWorksLikeThisNumber,
    LandingItWorksLikeThisText, LandingItWorksLikeThisTitle, LandingItWorksLikeThisTop,
    LandingItWorksLikeThisWrapper
} from "./style";
import BackgroundTitle from "../../backgroundTitle";
import Typography from "@mui/material/Typography";
import theme from "../../../../theme";

const LandingItWorksLikeThis = ({ element }) => {
    return (
        <LandingItWorksLikeThisWrapper>
            <BackgroundTitle
                maxWidth='860px'
                sx={{
                    marginTop: '30px',
                    [theme.breakpoints.down('xl')]: {
                        marginTop: '0px',
                    },
                    '@media (min-width: 1201px) and (min-height: 753px)': {
                        marginTop: '86px',
                    },
                }}
            >
                It works like this
            </BackgroundTitle>
            <Container direction='column'>
                <Typography variant='h2' className='title'>
                    {element.title}
                </Typography>
                <LandingItWorksLikeThisContent>
                    {element?.items?.map((item) => {
                        return (
                            <LandingItWorksLikeThisItem key={item.id}>
                                <LandingItWorksLikeThisTop>
                                    <LandingItWorksLikeThisNumber>
                                        {item.number}
                                    </LandingItWorksLikeThisNumber>
                                    <LandingItWorksLikeThisTitle
                                        variant="h4"
                                        component="div"
                                    >
                                        {item.title}
                                    </LandingItWorksLikeThisTitle>
                                </LandingItWorksLikeThisTop>
                                <LandingItWorksLikeThisText>
                                    {item.text}
                                </LandingItWorksLikeThisText>
                            </LandingItWorksLikeThisItem>
                        );
                    })}
                </LandingItWorksLikeThisContent>
            </Container>
        </LandingItWorksLikeThisWrapper>
    );
};

export default LandingItWorksLikeThis;

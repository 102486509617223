import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import theme from "../../../theme";
import palette from "../../../theme/palette";

export const LandingDetailsBlockFirstWrapper = styled(Box)({
  position: "relative",
  "@media (min-width: 1201px) and (min-height: 753px)": {
    marginTop: "95px",
  },

  "& .category-double-icon": {
    display: "none",
    "@media (min-width: 1201px) and (min-height: 753px)": {
      display: "block",
      top: "-60px",
      transform: "rotate(238deg)",
      position: "absolute",
      right: "0px",
    },
  },
  [theme.breakpoints.down("xl")]: {
    paddingTop: "120px",
  },
});

export const LandingDetailsBlockFirstDescription = styled(Typography)({
  margin: "24px 0",
  fontWeight: 400,
  [theme.breakpoints.down("xl")]: {
    fontSize: "18px",
    lineHeight: "28px",
    margin: "16px 0px 40px 0px",
  },
});

export const LandingDetailsBlockFirstList = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(380px, 1fr))",
  marginTop: "80px",
  gap: "20px 20px",

  [theme.breakpoints.down("xl")]: {
    marginTop: "16px",
    marginBottom: "40px",
  },
});

export const LandingDetailsBlockFirstItem = styled(Box)({
  width: "100%",
  padding: "26px",
  border: "1px solid #EBF2F7",
  backgroundColor: palette.secondary,

  "&.single": {
    maxWidth: "100%",
    [theme.breakpoints.down("xl")]: {
      maxWidth: "49%",
    },
  },

  "& .title": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "26px",
    paddingBottom: "26px",
    fontWeight: 600,
    textTransform: "uppercase",
    borderBottom: `1px solid ${palette.primary}`,
  },

  [theme.breakpoints.up("lg")]: {
    "&:hover": {
      color: palette.secondary,
      background: palette.primary,

      "& .title": {
        borderBottom: `1px solid ${palette.secondary}`,

        "& svg path": {
          fill: palette.purpleBlue,
        },
      },
    },
  },

  [theme.breakpoints.down("lg")]: {
    borderColor: palette.amethystSmoke,
    "& .title": {
      borderColor: palette.amethystSmoke,
    },
  },

  [theme.breakpoints.down("md")]: {
    maxWidth: "unset",
    height: "100%",
  },
});

export const SliderAdaptiveContainer = styled(Box)({
  overflow: "hidden",
  display: "flex",
  position: "relative",
  marginRight: "-16px",
  paddingRight: "16px",

  [theme.breakpoints.down("lg")]: {
    marginTop: "60px",

    "&.detail-slider": {
      flexDirection: "column",
      marginTop: 0,
      marginBottom: "40px",

      "& .range": {
        "& input[type='range']::-webkit-slider-runnable-track": {
          color: palette.amethystSmoke,
        },

        "& input[type='range']::-webkit-slider-thumb": {
          background: palette.amethystSmoke,
          boxShadow: `-80px 0 0 80px ${palette.amethystSmoke}`,
        },

        "& input[type='range']::-moz-range-progress": {
          backgroundColor: palette.amethystSmoke,
        },

        "& input[type=range]::-moz-range-thumb ": {
          background: palette.amethystSmoke,
          boxShadow: `-80px 0 0 80px ${palette.amethystSmoke}`,
        },

        "& input[type='range']::-ms-fill-lower": {
          backgroundColor: palette.amethystSmoke,
        },
      },
    },
  },

  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
});

export const SliderBackground = styled(Box)({
  position: "absolute",
  top: "120px",
  right: 0,
  width: "252px",
  height: "100%",
  background: palette.purpleBlue,
  zIndex: -1,

  "&.detail-slider": {
    background: palette.primary,
  },

  "@media(max-width: 481px)": {
    "&.outcome": {
      minHeight: "480px",
    },
  },
});

import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import palette from "../../../theme/palette";
import theme from "../../../theme";
import { motion } from "framer-motion";

export const LandingServicesWrapper = styled(Box)({
  marginTop: "50px",
  "& .MuiTypography-h4": {
    fontWeight: 400,

    "& strong": {
      color: palette.purpleBlue,
    },
  },

  [theme.breakpoints.down("xl")]: {
    marginTop: "0px",
  },

  [theme.breakpoints.down("lg")]: {
    h2: {
      fontSize: "32px",
      lineHeight: "48px",
    },

    "& .MuiTypography-h4, & .MuiTypography-h4 strong": {
      fontSize: "18px",
      lineHeight: "28px",
    },
  },
});

export const LandingServicesDescription = styled(Box)({
  marginTop: "24px",
  [theme.breakpoints.down("lg")]: {
    marginTop: "16px",
  },
});

export const LandingServicesList = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "30px",
  position: "relative",

  [theme.breakpoints.down("xl")]: {
    flexDirection: "column",
    marginTop: "24px",
  },
});

export const LandingServicesItemWrapper = styled(motion.div)({
  maxWidth: "580px",
  width: "100%",

  [theme.breakpoints.down("xl")]: {
    maxWidth: "100%",
  },
});

export const LandingServicesItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  height: "160px",
  marginTop: "16px",
  padding: "8px 0",

  "& .icon": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "124px",
    width: "100%",
  },

  "& .hoverIcon": {
    display: "none",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "124px",
    width: "100%",
  },

  "& .line": {
    width: "1px",
    height: "100%",
    backgroundColor: palette.amethystSmoke,
  },

  "& .description": {
    maxWidth: "360px",
    width: "100%",
    marginLeft: "24px",
    letterSpacing: "0.15px",

    "& strong": {
      color: palette.primary,
    },
  },

  "&:hover": {
    backgroundColor: palette.primary,

    "& .icon": {
      display: "none",
    },

    "& .hoverIcon": {
      display: "flex",
    },

    "& .description": {
      color: palette.secondary,

      "& strong": {
        color: palette.secondary,
      },
    },
  },

  [theme.breakpoints.down("xl")]: {
    maxWidth: "unset",
    marginTop: "24px",
    border: `1px solid ${palette.amethystSmoke}`,

    "& .description": {
      maxWidth: "100%",
      marginRight: "24px",
    },
  },

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    height: "auto",
    padding: "8px 16px 24px",

    "& .icon": {
      display: "none",
    },

    "& .hoverIcon": {
      display: "flex",
      margin: "30px auto",
    },

    "& .line": {
      width: "100%",
      height: "1px",
    },

    "& .description": {
      margin: "24px 0 0 0",
    },

    "&:hover": {
      backgroundColor: palette.secondary,

      "& .description": {
        color: palette.primary,
      },
    },
  },
});

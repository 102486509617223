import React from "react";
import { graphql } from "gatsby";
import LandingAbout from "../components/landing/landingAbout";
import LandingTop from "../components/landing/landingTop";
import LandingAdvantages from "../components/landing/landingAdvantages";
import LandingServices from "../components/landing/landingServices";
import LandingDetailsBlockFirst from "../components/landing/landingDetailsBlockFirst";
import LandingDetailsBlockSecond from "../components/landing/landingDetailsBlockSecond";
import { StyledLayout } from "../components/landing/landingTop/style";
import clsx from "clsx";
import LandingHeader from "../components/landing/landingHeader";
import Box from "@mui/material/Box";
import Footer from "../layout/footer";
import Typography from "@mui/material/Typography";
import Container from "../components/container";
import BackgroundTitle from "../components/landing/backgroundTitle";
import theme from "../theme";
import LandingWhoWeAre from "../components/landing/malevich-presentation/landingWhoWeAre";
import LandingWhyWeTheBest from "../components/landing/malevich-presentation/landingWhyWeTheBest";
import LandingTechnologyStack from "../components/landing/malevich-presentation/landingTechnologyStack";
import LandingExpertise from "../components/landing/malevich-presentation/landingExpertise";
import LandingItWorksLikeThis from "../components/landing/malevich-presentation/landingItWorksLikeThis";
import LandingOurTeam from "../components/landing/malevich-presentation/landingOurTeam";
import { useParallaxScroll } from "../hooks/slider-parallax";
import LandingInfrastructure from "../components/landing/malevich-presentation/landingInfrastructure";
import LandingOurWork from "../components/landing/malevich-presentation/landingOurWork";
import LandingPrices from "../components/landing/malevich-presentation/landingPrices";
import LandingAchieveGoals from "../components/landing/malevich-presentation/landingAchieveGoals";
import LandingWhyMalevich from "../components/landing/malevich-presentation/landingWhyMalevich";
import LandingTeamBanner from "../components/landing/malevich-presentation/landingTeamBanner";
import LandingInfoBlock from "../components/landing/malevich-presentation/landingInfoBlock";
import { useBreakpointValue } from "../hooks/use-breakpoint-value";
import "../layout/layout.css";

const SlideWrapper = ({ children, ...rest }) => {
  return (
    <Box className="slide-background" {...rest}>
      {children}
    </Box>
  );
};

const Landing = ({
  data: {
    strapi: { landing, categories },
  },
}) => {
  const isTablet = useBreakpointValue([
    "smallMobile",
    "mobile",
    "bigMobile",
    "tablet",
  ]);

  const skip = !!isTablet;

  const { activeSlideID, parallaxScrollTo } = useParallaxScroll({
    skip,
  });

  let lastSlideIndex = 1;

  if (typeof document !== "undefined")
    lastSlideIndex = document.querySelectorAll(".slide-background").length;

  const handleClickHireUs = () => {
    parallaxScrollTo(lastSlideIndex - 1);
  };

  return (
    <StyledLayout>
      {/* LANDING TOP */}
      <LandingTop
        title={landing?.data?.attributes?.title}
        description={landing?.data?.attributes?.description}
        malevichLanding={landing?.data?.attributes?.malevichLanding}
        image={landing?.data?.attributes.image}
        className={clsx("slide-background", "slide-background-translate")}
      />
      {/* LANDING HEADER */}
      <LandingHeader handleClickHireUs={handleClickHireUs} />
      <>
        {landing?.data?.attributes?.elements?.map((element) => {
          if (element?.__typename === "strapi_ComponentLandingWhoWeAre") {
            return (
              <SlideWrapper key={element.id}>
                {/* LANDING WHO WE ARE */}
                <LandingWhoWeAre element={element} />
                {/* LANDING WHY WE ARE THE BEST */}
                {landing?.data?.attributes?.elements?.map((element) => {
                  if (
                    element?.__typename ===
                    "strapi_ComponentLandingWhyWeTheBest"
                  ) {
                    return (
                      <LandingWhyWeTheBest key={element.id} element={element} />
                    );
                  }
                  return null;
                })}
              </SlideWrapper>
            );
          }
          return null;
        })}
      </>
      {/* LANDING EXPERTISE */}
      {landing?.data?.attributes?.elements?.map((element) => {
        if (element?.__typename === "strapi_ComponentLandingExpertise") {
          return (
            <SlideWrapper key={element.id}>
              <LandingExpertise element={element} categories={categories} />
            </SlideWrapper>
          );
        }
        return null;
      })}
      {/* LANDING TECHNOLOGY STACK */}
      {landing?.data?.attributes?.elements?.map((element) => {
        if (element?.__typename === "strapi_ComponentLandingTechnologyStack") {
          return (
            <SlideWrapper key={element.id}>
              <LandingTechnologyStack element={element} />
            </SlideWrapper>
          );
        }
        return null;
      })}
      {/* LANDING SERVICES */}
      {landing?.data?.attributes?.elements?.map((element) => {
        if (element?.__typename === "strapi_ComponentLandingServices") {
          return (
            <SlideWrapper
              key={element.id}
              data-value="strapi_ComponentLandingServices"
            >
              <LandingServices
                element={element}
                activeSlideID={activeSlideID}
              />
            </SlideWrapper>
          );
        }
        return null;
      })}
      {/* LANDING IT WORKS LIKE THIS */}
      {landing?.data?.attributes?.elements?.map((element) => {
        if (element?.__typename === "strapi_ComponentLandingItWorksLikeThis") {
          return (
            <SlideWrapper key={element.id}>
              <LandingItWorksLikeThis element={element} />
            </SlideWrapper>
          );
        }
        return null;
      })}

      {/* LANDING OUR TEAM */}
      {landing?.data?.attributes?.elements?.map((element) => {
        if (element?.__typename === "strapi_ComponentLandingOurTeam") {
          return (
            <SlideWrapper
              key={element.id}
              data-value="strapi_ComponentLandingOurTeam"
            >
              <LandingOurTeam element={element} activeSlideID={activeSlideID} />

              {/* LANDING INFRASTRUCTURE */}
              {landing?.data?.attributes?.elements?.map((element) => {
                if (
                  element?.__typename ===
                  "strapi_ComponentLandingInfrastructure"
                ) {
                  return (
                    <LandingInfrastructure key={element.id} element={element} />
                  );
                }
                return null;
              })}
            </SlideWrapper>
          );
        }
        return null;
      })}
      {/* LANDING OUR WORK */}
      {landing?.data?.attributes?.elements?.map((element) => {
        if (element?.__typename === "strapi_ComponentLandingOurWork") {
          return (
            <SlideWrapper key={element.id}>
              <LandingOurWork element={element} />
            </SlideWrapper>
          );
        }
        return null;
      })}
      {/* LANDING PRICES */}
      {landing?.data?.attributes?.elements?.map((element) => {
        if (element?.__typename === "strapi_ComponentLandingPrices") {
          return (
            <SlideWrapper key={element.id}>
              <LandingPrices element={element} />
            </SlideWrapper>
          );
        }
        return null;
      })}
      {/* LANDING ACHIEVE GOALS */}
      {landing?.data?.attributes?.elements?.map((element) => {
        if (element?.__typename === "strapi_ComponentLandingAchieveGoals") {
          return (
            <SlideWrapper key={element.id}>
              <LandingAchieveGoals element={element} />
            </SlideWrapper>
          );
        }
        return null;
      })}
      {/* LANDING WHY MALEVICH */}
      {landing?.data?.attributes?.elements?.map((element) => {
        if (element?.__typename === "strapi_ComponentLandingWhyMalevich") {
          return (
            <SlideWrapper
              position="relative"
              key={element.id}
              data-value="strapi_ComponentLandingWhyMalevich"
            >
              <LandingWhyMalevich
                element={element}
                activeSlideID={activeSlideID}
              />
            </SlideWrapper>
          );
        }
        return null;
      })}
      {/* LANDING TEAM BANNER */}
      {landing?.data?.attributes?.elements?.map((element) => {
        if (element?.__typename === "strapi_ComponentLandingTeamBanner") {
          return (
            <SlideWrapper key={element.id}>
              <LandingTeamBanner element={element} />
            </SlideWrapper>
          );
        }
        return null;
      })}
      {/* LANDING INFO BLOCK */}
      {landing?.data?.attributes?.elements?.map((element) => {
        if (element?.__typename === "strapi_ComponentLandingInfoBlock") {
          return (
            <SlideWrapper
              key={element.id}
              data-value="strapi_ComponentLandingInfoBlock"
              sx={{ margin: isTablet && "40px 0px 81px 0px" }}
            >
              <LandingInfoBlock
                element={element}
                activeSlideID={activeSlideID}
              />
            </SlideWrapper>
          );
        }
        return null;
      })}
      {/* LANDING ABOUT */}
      {landing?.data?.attributes?.elements?.map((element) => {
        if (element?.__typename === "strapi_ComponentLandingAbout") {
          return (
            <SlideWrapper key={element.id}>
              <LandingAbout element={element} />
            </SlideWrapper>
          );
        }
        return null;
      })}
      {/* LANDING ADVANTAGES */}
      {landing?.data?.attributes?.elements?.map((element) => {
        if (element?.__typename === "strapi_ComponentLandingAdvantages") {
          return (
            <SlideWrapper key={element.id}>
              <LandingAdvantages element={element} />
            </SlideWrapper>
          );
        }
        return null;
      })}

      {/* LANDING DETAIL BLOCK FIRST */}
      {landing?.data?.attributes?.elements?.map((element) => {
        if (element?.__typename === "strapi_ComponentLandingDetailBlockFirst") {
          return (
            <SlideWrapper key={element.id}>
              <LandingDetailsBlockFirst element={element} />
            </SlideWrapper>
          );
        }
        return null;
      })}
      {/* LANDING DETAIL BLOCK SECOND */}
      {landing?.data?.attributes?.elements?.map((element) => {
        if (
          element?.__typename === "strapi_ComponentLandingDetailBlockSecond"
        ) {
          return (
            <SlideWrapper key={element.id}>
              <LandingDetailsBlockSecond element={element} />
            </SlideWrapper>
          );
        }
        return null;
      })}
      <SlideWrapper
        position="relative"
        sx={{ [theme.breakpoints.down("xl")]: { paddingTop: "163px" } }}
      >
        <BackgroundTitle
          maxWidth="925px"
          top={"195px"}
          animateTop={isTablet && "0px"}
        >
          Ready to partner with us?
        </BackgroundTitle>
        <Container>
          <Typography variant="h2" className="footer-title">
            Ready TO PARTNER WITH US?
          </Typography>
        </Container>
        <Footer footerLinks />
      </SlideWrapper>
    </StyledLayout>
  );
};

export default Landing;

export const query = graphql`
  query landingQuery($id: ID!) {
    strapi {
      categories {
        data {
          id
          attributes {
            preview {
              title
              description
              icon {
                data {
                  attributes {
                    url
                    alternativeText
                    caption
                  }
                }
              }
              hoverIcon {
                data {
                  attributes {
                    url
                    alternativeText
                    caption
                  }
                }
              }
            }
          }
        }
      }

      landing(id: $id) {
        data {
          id
          attributes {
            title
            slug
            description
            malevichLanding
            image {
              data {
                attributes {
                  url
                  alternativeText
                  caption
                }
              }
            }
            elements {
              __typename

              ... on strapi_ComponentLandingWhoWeAre {
                id
                title
                description
                items {
                  id
                  quantity
                  text
                }
              }

              ... on strapi_ComponentLandingWhyWeTheBest {
                id
                title
                firstPlatform {
                  url
                  image {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                }

                secondPlatform {
                  url
                  image {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                }
                thirdPlatform {
                  url
                  image {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                }
              }

              ... on strapi_ComponentLandingExpertise {
                id
                title
              }

              ... on strapi_ComponentLandingTechnologyStack {
                id
                title
              }

              ... on strapi_ComponentLandingAbout {
                id
                title
                description
                items {
                  id
                  title
                  description
                  backgroundImage {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                }
              }
              ... on strapi_ComponentLandingAdvantages {
                id
                title
                items {
                  id
                  description
                  icon {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                  hoverIcon {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                }
              }
              ... on strapi_ComponentLandingServices {
                id
                title
                description
                items {
                  id
                  description
                  icon {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                  hoverIcon {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                }
              }
              ... on strapi_ComponentLandingItWorksLikeThis {
                id
                title
                items {
                  id
                  number
                  title
                  text
                }
              }
              ... on strapi_ComponentLandingOurTeam {
                id
                title
                items {
                  id
                  image {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                  title
                }
              }
              ... on strapi_ComponentLandingInfrastructure {
                id
                title
                items {
                  id
                  title
                  icon {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                }
              }
              ... on strapi_ComponentLandingOurWork {
                id
                title
                subtitle
                leftColumn {
                  id
                  title
                  subtitle
                  text
                  image {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                  technologies {
                    data {
                      id
                      attributes {
                        title
                        icon {
                          data {
                            attributes {
                              url
                              alternativeText
                              caption
                            }
                          }
                        }
                      }
                    }
                  }
                }
                rightColumn {
                  id
                  title
                  subtitle
                  text
                  image {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                  technologies {
                    data {
                      id
                      attributes {
                        title
                        icon {
                          data {
                            attributes {
                              url
                              alternativeText
                              caption
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on strapi_ComponentLandingPrices {
                id
                title
                description
                items {
                  id
                  price
                  text
                  icon {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                  hoverIcon {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                }
              }
              ... on strapi_ComponentLandingAchieveGoals {
                id
                title
                strapi {
                  title
                  description
                  image {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                  listTitle
                  list
                }
                pwa {
                  title
                  description
                  image {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                  listTitle
                  list
                  priceBadge
                  infoBlock
                  infoLinkText
                  infoLinkUrl
                  scheduleImage {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                  mobileScheduleImage {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                  statisticTitle
                  statisticDescription
                  statisticLinkText
                  statisticLinkUrl
                }
                mobileFirst {
                  title
                  mobileFirstTitle
                  mobileFirstSubtitle
                  description
                }
                schedule {
                  id
                  title
                  linkText
                  linkUrl
                  image {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                  mobileImage {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                }
              }
              ... on strapi_ComponentLandingWhyMalevich {
                id
                title
                image {
                  data {
                    attributes {
                      url
                      alternativeText
                      caption
                    }
                  }
                }
                items {
                  id
                  description
                }
              }
              ... on strapi_ComponentLandingTeamBanner {
                id
                image {
                  data {
                    attributes {
                      url
                      alternativeText
                      caption
                    }
                  }
                }
              }
              ... on strapi_ComponentLandingInfoBlock {
                id
                firstText
                secondText
                thirdText
              }

              ... on strapi_ComponentLandingDetailBlockFirst {
                id
                title
                description
                subtitle
                items {
                  id
                  title
                  description
                }
              }
              ... on strapi_ComponentLandingDetailBlockSecond {
                id
                title
                description
                items {
                  id
                  title
                  description
                }
              }
            }
          }
        }
      }
    }
  }
`;

import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import theme from "../../../theme";
import palette from "../../../theme/palette";

export const StyledHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: "72px",

  [theme.breakpoints.down("md")]: {
    height: "68px",
  },
});

export const HeaderWrapper = styled(Box)({
  position: "sticky",
  top: 0,
  backgroundColor: palette.secondary,
  borderBottom: `1px solid ${palette.primary}`,
  width: "100%",
  zIndex: 19,
});

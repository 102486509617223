import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "../../container";
import { HOST_IMAGE_URL } from "../../../constants";
import {
  ArrowContainer,
  HomeTopAnimation,
  ImgContainer,
  InfoBlockText,
  LandingTopContent,
  LandingTopDescription,
  LandingTopInfo,
  LandingTopWrapper,
  PreviewHeaderAnimate,
  PreviewHeaderBottom,
} from "./style";
import { LongArrowIcon } from "../../../assets/icons/LongArrowIcon";
import LottieIcon from "../../lottieIcons";
import homeAnimation from "../../../assets/animations/homeTopAnimation.json";
import { useRef } from "react";
import { useInView } from "framer-motion";

const LandingTop = ({
  title,
  description,
  malevichLanding,
  image,
  ...rest
}) => {
  const topAnimation = {
    animationData: homeAnimation,
    loop: 0,
    autoplay: true,
  };

  const reference = useRef();

  const isInView = useInView(reference, {
    once: true,
  });

  return (
    <Box {...rest}>
      <Container>
        <LandingTopWrapper>
          <PreviewHeaderBottom
            initial={{
              y: "-100%",
            }}
            animate={{
              y: "0%",
            }}
            transition={{
              type: "tween",
              duration: 1,
              delay: 2.5,
            }}
            viewport={{
              once: true,
              amount: 1,
            }}
          >
            <ArrowContainer>
              <LongArrowIcon />
              <Typography variant="subtitle2">explore</Typography>
            </ArrowContainer>
          </PreviewHeaderBottom>
          <LandingTopContent className="landing-top-content">
            <LandingTopInfo>
              <Typography variant="h1" fontWeight="600">
                {title}
              </Typography>
              <LandingTopDescription
                variant="h4"
                component="h6"
                fontWeight="400"
              >
                {description}
              </LandingTopDescription>
            </LandingTopInfo>
            {malevichLanding === false ? (
              <img
                src={`${HOST_IMAGE_URL + image?.data?.attributes?.url}`}
                alt={image?.data?.attributes?.alternativeText}
                title={image?.data?.attributes?.caption}
              />
            ) : (
              <ImgContainer>
                <HomeTopAnimation>
                  <LottieIcon
                    options={topAnimation}
                    replayInView
                    disableOnHover
                  />
                </HomeTopAnimation>
              </ImgContainer>
            )}
          </LandingTopContent>
        </LandingTopWrapper>
      </Container>
      <PreviewHeaderAnimate
        ref={reference}
        initial={{
          scale: 1,
          y: 0,
          x: 0,
        }}
        animate={{
          ...(isInView && {
            y: "-100%",
            x: "-100%",
            scale: 0,
          }),
        }}
        transition={{
          type: "tween",
          duration: 1.3,
          delay: 1.8,
        }}
        viewport={{
          once: true,
          amount: 1,
        }}
      >
        <Container direction="column">
          <InfoBlockText
            initial={{
              y: 50,
              opacity: 0,
            }}
            whileInView={{
              y: 0,
              opacity: 1,
            }}
            transition={{
              type: "tween",
              delay: 0,
              duration: 0.5,
              ease: "easeIn",
            }}
            viewport={{
              once: true,
              amount: 1,
            }}
          >
            <Typography variant="h2" fontWeight={500}>
              Welcome to
            </Typography>
          </InfoBlockText>
          <InfoBlockText
            initial={{
              y: 50,
              opacity: 0,
            }}
            whileInView={{
              y: 0,
              opacity: 1,
            }}
            transition={{
              type: "tween",
              delay: 0.6,
              duration: 0.5,
              ease: "easeIn",
            }}
            viewport={{
              once: true,
              amount: 1,
            }}
          >
            <Typography variant="h1" fontWeight={600}>
              Malevich
            </Typography>
          </InfoBlockText>
        </Container>
      </PreviewHeaderAnimate>
    </Box>
  );
};

export default LandingTop;

import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";

import theme from "../../../../theme";
import palette from "../../../../theme/palette";

export const OurWorkWrapper = styled(Box)({
  position: "relative",
  marginBottom: "38px",
  marginTop: "40px",

  "& .blockTitle": {
    marginTop: "138px",
  },
  "@media (min-width: 1201px) and (min-height: 753px)": {
    overflowY: "scroll",
    scrollbarColor: "transparent transparent",
    scrollbarWidth: "thin",

    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px transparent",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent",
      borderRadius: "3px",
    },
  },

  "@media (min-width: 1201px) and (min-height: 959px)": {
    "& .blockTitle": {
      marginTop: "185px",
    },
  },

  [theme.breakpoints.down("xl")]: {
    marginTop: "0px",
    overflowY: "unset",
    "& .blockTitle": {
      marginTop: "118px",
    },
  },
  [theme.breakpoints.down("md")]: {
    marginBottom: "64px",
  },
});

export const OurWorkSubTitle = styled(Typography)({
  marginTop: "24px",
});

export const OurWorkContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {
    flexDirection: "column",
  },
});

export const OurWorkContent = styled(Box)({
  marginTop: "132px",

  [theme.breakpoints.down("xl")]: {
    marginTop: "48px",
  },
});

export const OurWorkItem = styled(Box)({
  position: "relative",
  maxWidth: "580px",
  width: "100%",
  height: "459px",
  marginBottom: "26px",
  background: "rgb(0, 0, 0)",

  "& img": {
    opacity: 1,
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
  },

  "& .info": {
    display: "none",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    padding: "24px",
    color: palette.secondary,
  },

  "&:hover": {
    "& img": {
      opacity: 0.1,
    },

    "& .info": {
      display: "flex",
    },

    "& .technologies": {
      "& img": {
        opacity: 1,
      },
    },
  },
});

export const OurWorkItemTitle = styled(Typography)({
  fontWeight: 600,
  letterSpacing: "-0.02em",
});

export const OurWorkItemSubTitle = styled(Typography)({
  paddingTop: "8px",
  lineHeight: "24px",
  letterSpacing: "0.15px",
});

export const OurWorkItemText = styled(Typography)({
  marginTop: "24px",
  letterSpacing: "0.15px",

  "& p": {
    marginBottom: "16px",
  },

  "& ul": {
    marginBottom: "16px",
  },

  "& li": {
    listStyle: "inside",
    listStyleType: "disc",
  },
  [theme.breakpoints.down("md")]: {
    marginTop: "16px",

    "& p": {
      fontSize: "14px",
      lineHeight: "22px",
    },

    "&.cutText": {
      overflow: "hidden",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 5,
      whiteSpace: "pre-wrap",
    },
  },
});

export const OurWorkItemTechnologiesList = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  flexWrap: "wrap",
});

export const OurWorkItemTechnology = styled(Box)({
  marginRight: "16px",

  "&:last-of-type": {
    marginRight: 0,
  },

  [theme.breakpoints.down("md")]: {
    height: "40px",
    marginTop: "24px",

    img: {
      width: "auto",
      height: "41px",
    },
  },
});

export const OurWorkItemWrapper = styled(Box)({
  background: palette.primary,
  color: palette.secondary,
  height: "100%",
  display: "flex !important",
  flexDirection: "column",

  "& .ourWorkDescription": {
    padding: "24px",
    flex: 1,

    h6: {
      fontSize: "20px",
      lineHeight: "28px",
    },

    "& .MuiTypography-root": {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
  img: {
    width: "100%",
  },
});

export const ReadMoreWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "0px 24px 24px 24px",
  p: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    cursor: "pointer",
  },
  svg: {
    minWidth: "36px",
    minHeight: "36px",
    cursor: "pointer",
  },
});

export const OurWorkDescriptionWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  flex: 1,
});

export const ModalCardWrapper = styled(Modal)({
  position: "fixed",
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  zIndex: 5,
  width: "100%",
  height: "100%",
  background: palette.primary,
  color: palette.secondary,
  padding: "24px 24px 0px 24px",
});

import React from "react";
import Typography from "@mui/material/Typography";
import Container from "../../container";
import {
  LandingDetailsBlockFirstDescription,
  LandingDetailsBlockFirstItem,
  LandingDetailsBlockFirstList,
  LandingDetailsBlockFirstWrapper,
  SliderAdaptiveContainer,
  SliderBackground,
} from "./style";
import Cube from "../../../assets/icons/Cube";
import { CategoryDoubleBlockIcon } from "../../../assets/icons/CategoryDoubleBlockIcon";

import Box from "@mui/material/Box";
import BackgroundTitle from "../backgroundTitle";
import { useBreakpointValue } from "../../../hooks/use-breakpoint-value";
import DetailSlider from "./DetailSlider";

const LandingDetailsBlockFirst = ({ element }) => {
  const isMobile = useBreakpointValue(["smallMobile", "mobile", "bigMobile"]);

  return (
    <LandingDetailsBlockFirstWrapper>
      <BackgroundTitle maxWidth="800px" className="background-title">
        {element.title}
      </BackgroundTitle>
      <Container direction="column">
        <Box display="flex">
          <Typography variant="h2">{element.title}</Typography>
          <CategoryDoubleBlockIcon />
        </Box>
        <LandingDetailsBlockFirstDescription variant="h4" component="div">
          {element.description}
        </LandingDetailsBlockFirstDescription>
        <Typography variant="h4" component="div">
          <strong>{element.subtitle}</strong>
        </Typography>
        {isMobile ? (
          <SliderAdaptiveContainer className="detail-slider">
            <DetailSlider key={element.id} element={element} />
            <SliderBackground className="detail-slider" />
          </SliderAdaptiveContainer>
        ) : (
          <LandingDetailsBlockFirstList>
            {element?.items?.map((item) => {
              return (
                <LandingDetailsBlockFirstItem key={item.id}>
                  <Typography variant="body2" className="title">
                    {item.title}
                    <Cube />
                  </Typography>
                  <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  />
                </LandingDetailsBlockFirstItem>
              );
            })}
          </LandingDetailsBlockFirstList>
        )}
      </Container>
    </LandingDetailsBlockFirstWrapper>
  );
};

export default LandingDetailsBlockFirst;

import { styled } from "@mui/system";
import { motion } from "framer-motion";

import theme from "../../../theme";

export const StyledBackgroundTitle = styled(motion.div)({
    position: "absolute",
    width: "100%",
    fontSize: "128px",
    fontWeight: 900,
    lineHeight: "192px",
    letterSpacing: "-0.02em",
    opacity: "0.05",

    [theme.breakpoints.down("xl")]: {
        left: "-16px !important",
        width: "375px",
        fontSize: "84px",
        lineHeight: "80px",
    },

    [theme.breakpoints.down("xs")]: {
        width: "100%",
        fontSize: "70px",
        lineHeight: "76px",
    },

    "@media (max-width: 375px)": {
        maxWidth: "300px !important",
    },
});

import React from "react";
import { LandingDetailsBlockFirstItem } from "./style";
import Typography from "@mui/material/Typography";
import Cube from "../../../assets/icons/Cube";
import { Slider } from "../../slider";

const DetailSlider = ({ element }) => {
  return (
    <>
      <Slider
        settings={{
          autoplay: false,
        }}
        isArrowBox
        portalIdNext="landing-details-next-arrow"
        portalIdPrev="landing-details-prev-arrow"
        width="100%"
        margin="0px"
        wrapperProps={{
          marginTop: "26px",
          marginBottom: "68px",
        }}
      >
        {element?.items?.map((item) => {
          return (
            <LandingDetailsBlockFirstItem key={item.id}>
              <Typography variant="body2" className="title">
                {item.title}
                <Cube />
              </Typography>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              />
            </LandingDetailsBlockFirstItem>
          );
        })}
      </Slider>
    </>
  );
};

export default DetailSlider;

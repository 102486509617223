import { useInView } from "framer-motion";
import { useRef } from "react";
import React from "react";
import { StyledBackgroundTitle} from "./style";
const BackgroundTitle = ({ maxWidth, top, animateTop, ...properties }) => {
  const reference = useRef();

  const isInView = useInView(reference, {
    once: true,
  });

  return (
    <StyledBackgroundTitle
      ref={reference}
      initial={{
        top: top || 0,
        left: "-100%",
      }}
      animate={{
        ...(isInView && {
          top: animateTop,
          left: 72,
          maxWidth,
        }),
      }}
      transition={{
        ease: "linear",
        duration: 1.75,
        type: "tween",
      }}
      viewport={{
        once: true,
        amount: 1,
      }}
      {...properties}
    />
  );
};

export default BackgroundTitle;

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef } from "react";
import {
  AchieveGoalsBottomMenu,
  AchieveGoalsTitle,
  AchieveGoalsWrapper,
  BottomMenuContent,
  FirstLeft,
  FirstRight,
  FirstRightList,
  LastLeft,
  LastRight,
  MainContainer,
  MobileFirstWrapper,
  PriceBadge,
  SecondDescriptionWrapper,
  SecondLeft,
  SecondList,
  SecondRight,
  SourceWrapper,
} from "./style";
import { HOST_IMAGE_URL } from "../../../../constants";
import BackgroundTitle from "../../backgroundTitle";
import Container from "../../../container";
import theme from "../../../../theme";
import SmartphoneFillIcon from "../../../../assets/icons/SmartphoneFillIcon";
import { useBreakpointValue } from "../../../../hooks/use-breakpoint-value";

const LandingAchieveGoals = ({ element }) => {
  const isMobile = useBreakpointValue(["smallMobile", "mobile"]);

  const referenceStrapi = useRef();
  const referencePWA = useRef();
  const referenceMobileFirst = useRef();

  useEffect(() => {
    let observer;

    if (
      referenceStrapi.current &&
      referencePWA.current &&
      referenceMobileFirst.current
    ) {
      const options = {
        threshold: 0.16,
      };
      observer = new IntersectionObserver((entries) => {
        for (const entry of entries) {
          const navElement = document.querySelector(
            `a[href="#${entry.target.id}"]`
          );

          if (entry.isIntersecting) {
            if (!navElement.classList.contains("active")) {
              navElement.classList.add("active");
            }
          } else if (navElement.classList.contains("active")) {
            navElement.classList.remove("active");
          }
        }
      }, options);

      observer.observe(referenceStrapi.current);
      observer.observe(referencePWA.current);
      observer.observe(referenceMobileFirst.current);
    }
    return () => observer.disconnect();
  }, []);

  const listInnerReference = useRef();

  const goalsScrollIsDisabled = useRef(true);

  const onWheel = (e) => {
    if (goalsScrollIsDisabled.current) {
      e.stopPropagation();
    }
  };

  const onScroll = () => {
    if (listInnerReference.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        listInnerReference.current;
      if (Math.ceil(scrollTop + clientHeight) === scrollHeight + 1) {
        goalsScrollIsDisabled.current = false;
      } else if (Math.ceil(scrollTop + clientHeight) === scrollHeight) {
        goalsScrollIsDisabled.current = false;
      } else if (scrollTop === 0) {
        goalsScrollIsDisabled.current = false;
      } else {
        goalsScrollIsDisabled.current = true;
      }
    }
  };

  return (
    <AchieveGoalsWrapper
      onWheel={onWheel}
      onScroll={onScroll}
      ref={listInnerReference}
    >
      <BackgroundTitle
        maxWidth="1080px"
        sx={{
          position: "sticky",
          height: "576px",
          top: "72px",
          [theme.breakpoints.down("xl")]: {
            position: "relative",
            top: "unset",
            height: "unset",
          },
        }}
      >
        {element.title}
      </BackgroundTitle>
      {/* First Section */}
      <Container
        direction="column"
        sx={{
          marginTop: "-488px",
          [theme.breakpoints.down("xl")]: {
            marginTop: "-300px",
          },
        }}
        ref={referenceStrapi}
        id="strapi"
      >
        <AchieveGoalsTitle variant="h2">{element.title}</AchieveGoalsTitle>
        <MainContainer>
          <FirstLeft>
            <Typography variant="h4" component="div">
              {element?.strapi?.title}
            </Typography>
            <Box
              sx={{
                img: {
                  maxWidth: "480px",
                  margin: "40px 0",

                  [theme.breakpoints.down("xl")]: {
                    width: "100%",
                    height: "auto",
                  },
                },
              }}
            >
              <img
                src={`${
                  HOST_IMAGE_URL + element?.strapi?.image?.data?.attributes?.url
                }`}
                alt={element?.strapi?.image?.data?.attributes?.alternativeText}
                title={element?.strapi?.image?.data?.attributes?.caption}
              />
            </Box>
            <Typography
              variant="body2"
              className="description"
              dangerouslySetInnerHTML={{
                __html: element?.strapi?.description,
              }}
            />
          </FirstLeft>
          <FirstRight>
            <Typography variant="body1" fontWeight={500}>
              {element?.strapi?.listTitle}
            </Typography>
            <FirstRightList
              dangerouslySetInnerHTML={{
                __html: element?.strapi?.list,
              }}
            />
          </FirstRight>
        </MainContainer>
      </Container>
      {/* End Of First Section */}

      {/* Second Section */}
      <Container ref={referencePWA} id="pwa">
        <MainContainer
          sx={{
            [theme.breakpoints.down("xl")]: {
              flexDirection: "column-reverse",
            },
          }}
        >
          <SecondLeft>
            <Typography
              variant="body1"
              sx={{
                marginBottom: "24px",
              }}
            >
              {element?.pwa?.listTitle}
            </Typography>
            <SecondList
              dangerouslySetInnerHTML={{
                __html: element?.pwa?.list,
              }}
            />
            <PriceBadge>{element?.pwa?.priceBadge}</PriceBadge>
            <Typography variant="body1" maxWidth="545px">
              {element?.pwa?.infoBlock}
            </Typography>
            <SourceWrapper>
              Source:
              <Link href={element?.pwa?.infoLinkUrl}>
                {element?.pwa?.infoLinkText}
              </Link>
            </SourceWrapper>
            <Box
              sx={{
                svg: {
                  [theme.breakpoints.down("xl")]: {
                    width: "100%",
                    height: "auto",
                    marginBottom: "0px",
                  },
                },
              }}
            >
              {isMobile ? (
                <img
                  src={`${
                    HOST_IMAGE_URL +
                    element?.pwa?.mobileScheduleImage?.data?.attributes?.url
                  }`}
                  alt={
                    element?.pwa?.mobileScheduleImage?.data?.attributes
                      ?.alternativeText
                  }
                  title={
                    element?.pwa?.mobileScheduleImage?.data?.attributes?.caption
                  }
                />
              ) : (
                <img
                  src={`${
                    HOST_IMAGE_URL +
                    element?.pwa?.scheduleImage?.data?.attributes?.url
                  }`}
                  alt={
                    element?.pwa?.scheduleImage?.data?.attributes
                      ?.alternativeText
                  }
                  title={element?.pwa?.scheduleImage?.data?.attributes?.caption}
                />
              )}
            </Box>
            <Typography
              variant="body1"
              fontWeight="600"
              sx={{
                display: "block",
                marginBottom: "24px",
              }}
            >
              {element?.pwa?.statisticTitle}
            </Typography>
            <SecondDescriptionWrapper>
              <Typography
                variant="body1"
                className="description"
                dangerouslySetInnerHTML={{
                  __html: element?.pwa?.statisticDescription,
                }}
              />
            </SecondDescriptionWrapper>
            <SourceWrapper>
              Source:
              <Link href={element?.pwa?.statisticLinkUrl}>
                {element?.pwa?.statisticLinkText}
              </Link>
            </SourceWrapper>
          </SecondLeft>
          <SecondRight>
            <Typography variant="h4" component="div">
              {element?.pwa?.title}
            </Typography>
            <Box
              sx={{
                margin: "40px 0",

                [theme.breakpoints.down("xl")]: {
                  display: "flex",
                  justifyContent: "center",
                },
              }}
            >
              <img
                src={`${
                  HOST_IMAGE_URL + element?.pwa?.image?.data?.attributes?.url
                }`}
                alt={element?.pwa?.image?.data?.attributes?.alternativeText}
                title={element?.pwa?.image?.data?.attributes?.caption}
              />
            </Box>
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: element?.pwa?.description,
              }}
            />
          </SecondRight>
        </MainContainer>
      </Container>
      {/* End Of Second Section */}

      {/* Last Section */}
      <Container ref={referenceMobileFirst} id="mobileFirst">
        <MainContainer>
          <LastLeft>
            <Typography variant="h4" component="div">
              {element?.mobileFirst?.title}
            </Typography>
            <MobileFirstWrapper>
              <SmartphoneFillIcon />
              {element?.mobileFirst?.mobileFirstTitle}
            </MobileFirstWrapper>
            <Typography
              variant="body1"
              fontWeight="600"
              sx={{
                display: "block",
                marginBottom: "24px",
              }}
            >
              {element?.mobileFirst?.mobileFirstSubtitle}
            </Typography>
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: element?.mobileFirst?.description,
              }}
            />
          </LastLeft>
          <LastRight>
            {element?.schedule?.map((item) => {
              return (
                <Box
                  key={item.id}
                  sx={{
                    marginBottom: "64px",
                    [theme.breakpoints.down("xl")]: {
                      marginBottom: "40px",
                    },
                  }}
                >
                  <Typography variant="body1">{item.title}</Typography>
                  {isMobile ? (
                    <img
                      src={`${
                        HOST_IMAGE_URL +
                        item?.mobileImage?.data?.attributes?.url
                      }`}
                      alt={item?.mobileImage?.data?.attributes?.alternativeText}
                      title={item?.mobileImage?.data?.attributes?.caption}
                    />
                  ) : (
                    <img
                      src={`${
                        HOST_IMAGE_URL + item?.image?.data?.attributes?.url
                      }`}
                      alt={item?.image?.data?.attributes?.alternativeText}
                      title={item?.image?.data?.attributes?.caption}
                    />
                  )}
                  <SourceWrapper
                    sx={{
                      justifyContent: "flex-end",
                    }}
                  >
                    source:
                    <Link href={item.linkUrl}>{item.linkText}</Link>
                  </SourceWrapper>
                </Box>
              );
            })}
          </LastRight>
        </MainContainer>
      </Container>
      {/* End Of Last Section */}
      <AchieveGoalsBottomMenu>
        <BottomMenuContent href="#strapi" underline="none" color="unset">
          Strapi
        </BottomMenuContent>
        <BottomMenuContent href="#pwa" underline="none" color="unset">
          PWA
        </BottomMenuContent>
        <BottomMenuContent href="#mobileFirst" underline="none" color="unset">
          Mobile First
        </BottomMenuContent>
      </AchieveGoalsBottomMenu>
    </AchieveGoalsWrapper>
  );
};

export default LandingAchieveGoals;

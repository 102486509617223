import { Box, Typography } from '@mui/material';
import React from 'react';

import {
    ModalCardWrapper,
    OurWorkDescriptionWrapper,
    OurWorkItemSubTitle,
    OurWorkItemTechnologiesList,
    OurWorkItemTechnology, OurWorkItemText, OurWorkItemTitle, ReadMoreWrapper
} from "./style";
import theme from "../../../../theme";
import MinusCircle from "../../../../assets/icons/MinusCircle";

const ModalCard = ({ open, close, item }) => {
  return (
    <ModalCardWrapper open={open}>
      <OurWorkDescriptionWrapper
        sx={{
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <OurWorkItemTitle variant='subtitle2'>{item?.title}</OurWorkItemTitle>
          {item?.subTitle !== '' && (
            <OurWorkItemSubTitle variant='body2'>{item?.subTitle}</OurWorkItemSubTitle>
          )}
          <OurWorkItemText>{item?.text}</OurWorkItemText>
        </Box>
        <Box>
          <OurWorkItemTechnologiesList>
            {item?.technologies?.map((technology) => {
              return (
                <OurWorkItemTechnology
                  key={technology.id}
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      height: '50px',
                      svg: {
                        height: '50px',
                      },
                    },
                  }}
                >
                  {technology.icon}
                </OurWorkItemTechnology>
              );
            })}
          </OurWorkItemTechnologiesList>
          <ReadMoreWrapper
            onClick={close}
            sx={{
              margin: '24px 0px',
            }}
          >
            <Typography variant='body2'>Close card</Typography>
            <MinusCircle />
          </ReadMoreWrapper>
        </Box>
      </OurWorkDescriptionWrapper>
    </ModalCardWrapper>
  );
};

export default ModalCard;

import React from "react";
import Container from "../../../container";
import {
  WhyWeTheBest,
  WhyWeTheBestArrow,
  WhyWeTheBestContent,
  WhyWeTheBestItem,
  WhyWeTheBestTitle,
} from "./style";
import { ArrowLongIcon } from "../../../../assets/icons/ArrowLongIcon";
import { HOST_IMAGE_URL } from "../../../../constants";

const LandingWhyWeTheBest = ({ element }) => {
  return (
    <Container>
      <WhyWeTheBest>
        <WhyWeTheBestTitle variant="h3">{element.title}</WhyWeTheBestTitle>
        <WhyWeTheBestArrow>
          <ArrowLongIcon />
        </WhyWeTheBestArrow>
        <WhyWeTheBestContent>
          <WhyWeTheBestItem>
            <a
              href={element?.firstPlatform?.url}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`${
                  HOST_IMAGE_URL +
                  element?.firstPlatform?.image?.data?.attributes?.url
                }`}
                alt={
                  element?.firstPlatform?.image?.data?.attributes
                    ?.alternativeText
                }
                title={element?.firstPlatform?.image?.data?.attributes?.caption}
              />
            </a>
            <a
              href={element?.secondPlatform?.url}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`${
                  HOST_IMAGE_URL +
                  element?.secondPlatform?.image?.data?.attributes?.url
                }`}
                alt={
                  element?.secondPlatform?.image?.data?.attributes
                    ?.alternativeText
                }
                title={
                  element?.secondPlatform?.image?.data?.attributes?.caption
                }
              />
            </a>
          </WhyWeTheBestItem>
          <WhyWeTheBestItem>
            <a
              href={element?.thirdPlatform?.url}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`${
                  HOST_IMAGE_URL +
                  element?.thirdPlatform?.image?.data?.attributes?.url
                }`}
                alt={
                  element?.thirdPlatform?.image?.data?.attributes
                    ?.alternativeText
                }
                title={element?.thirdPlatform?.image?.data?.attributes?.caption}
              />
            </a>
          </WhyWeTheBestItem>
        </WhyWeTheBestContent>
      </WhyWeTheBest>
    </Container>
  );
};

export default LandingWhyWeTheBest;

import React from 'react';
import {
    OurWorkItem,
    OurWorkItemSubTitle,
    OurWorkItemTechnologiesList, OurWorkItemTechnology,
    OurWorkItemText,
    OurWorkItemTitle
} from "./style";
import { HOST_IMAGE_URL } from "../../../../constants";

const OurWorkItems = ({ item, ...rest }) => {
  return (
    <OurWorkItem {...rest}>
        <img
            src={`${
                HOST_IMAGE_URL + item?.image?.data?.attributes?.url
            }`}
            alt={item?.image?.data?.attributes?.alternativeText}
            title={item?.image?.data?.attributes?.caption}
        />
        <div className='info'>
            <div>
                <OurWorkItemTitle variant='h4' component='div'>{item.title}</OurWorkItemTitle>
                {item.subtitle !== '' && (
                    <OurWorkItemSubTitle variant='body2' component='div'>{item.subtitle}</OurWorkItemSubTitle>
                )}
                <OurWorkItemText
                    variant='subtitle1'
                    component='div'
                    dangerouslySetInnerHTML={{
                        __html: item.text,
                    }}
                />
            </div>
            <OurWorkItemTechnologiesList className='technologies'>
                {item?.technologies?.data?.map((technology) => {
                    return (
                        <OurWorkItemTechnology key={technology.id}>
                            <img
                                src={`${
                                    HOST_IMAGE_URL + technology?.attributes?.icon?.data?.attributes?.url
                                }`}
                                alt={technology?.attributes?.icon?.data?.attributes?.alternativeText}
                                title={technology?.attributes?.icon?.data?.attributes?.caption}
                            />
                        </OurWorkItemTechnology>
                    );
                })}
            </OurWorkItemTechnologiesList>
        </div>
    </OurWorkItem>
  );
};

export default OurWorkItems;

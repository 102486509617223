import React from "react";
export const TechnologyExpressIcon = ({ width = 148, height = 74 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 148 74'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <rect width='148' height='74' fill='white' />
        <path
          d='M49.3467 51.4959V23.0731H50.8896V27.5444C51.0718 27.3463 51.2401 27.1359 51.3934 26.9146C52.0238 25.6059 53.0143 24.504 54.2486 23.7381C55.483 22.9723 56.9101 22.5741 58.3626 22.5903C61.8787 22.4854 64.7336 23.7869 66.4129 26.9146C67.4815 28.8576 68.0695 31.0281 68.1279 33.2448C68.1864 35.4615 67.7135 37.66 66.7488 39.6566C65.1849 43.1622 61.3225 44.9045 57.1871 44.2643C55.9002 44.1547 54.6612 43.7246 53.5832 43.0132C52.5052 42.3019 51.6225 41.3319 51.0155 40.1919V51.4959H49.3467ZM50.8896 33.1282C51.026 34.5136 51.0785 35.5002 51.2359 36.4658C51.8447 40.2653 54.0908 42.522 57.6174 42.9313C59.2733 43.2035 60.9724 42.893 62.425 42.0528C63.8777 41.2126 64.994 39.8947 65.5838 38.3236C66.2615 36.7076 66.5876 34.966 66.5406 33.2143C66.4935 31.4626 66.0744 29.7409 65.3109 28.1636C64.6814 26.7555 63.6107 25.5904 62.2607 24.8444C60.9107 24.0984 59.3545 23.8119 57.8273 24.0283C56.3935 24.1321 55.0276 24.6802 53.9197 25.5963C52.8117 26.5124 52.0167 27.7509 51.6453 29.1397C51.3165 30.4535 51.0642 31.7852 50.8896 33.1282Z'
          fill='#151515'
        />
        <path
          d='M99.6017 36.9906C99.3679 38.9918 98.4153 40.84 96.921 42.1914C95.4267 43.5428 93.4924 44.3056 91.4779 44.3377C85.0125 44.6631 81.9792 40.3703 81.4964 35.4163C81.1612 32.726 81.5981 29.9958 82.7559 27.5444C83.5621 25.8628 84.8826 24.4815 86.5261 23.6003C88.1697 22.7191 90.051 22.3837 91.8977 22.6428C93.6535 22.7916 95.3179 23.489 96.6553 24.6362C97.9928 25.7833 98.9354 27.3222 99.3498 29.0348C99.6783 30.5946 99.9201 32.1714 100.074 33.7579H83.0812C82.7663 38.2501 85.1804 41.8607 88.602 42.7319C92.8634 43.7815 96.5054 41.9342 97.8279 37.8828C98.1218 36.8437 98.6571 36.6968 99.6017 36.9906ZM83.0812 32.32H98.2687C98.1743 27.5339 95.1934 24.0702 91.1525 24.0178C86.5763 23.9443 83.2806 27.282 83.0812 32.32Z'
          fill='#151515'
        />
        <path
          d='M102.729 37.0956H104.219C104.274 38.1922 104.631 39.2525 105.249 40.1598C105.867 41.0672 106.724 41.7864 107.725 42.2385C108.981 42.804 110.349 43.0788 111.727 43.0424C113.104 43.0061 114.456 42.6596 115.681 42.0286C116.348 41.7277 116.907 41.2292 117.283 40.6004C117.658 39.9717 117.832 39.2429 117.78 38.5125C117.818 37.808 117.626 37.1102 117.232 36.525C116.838 35.9397 116.264 35.4988 115.597 35.2693C113.959 34.6605 112.217 34.3247 110.548 33.7894C108.812 33.2949 107.114 32.674 105.468 31.9316C102.781 30.6196 102.613 25.5081 105.657 23.8918C107.216 23.0123 108.97 22.5371 110.759 22.5096C112.548 22.4821 114.316 22.9031 115.901 23.7343C116.861 24.2649 117.637 25.0756 118.124 26.0582C118.612 27.0408 118.788 28.1487 118.63 29.2342H117.349C117.349 29.1712 117.234 29.1187 117.234 29.0557C117.077 24.9728 113.655 23.7133 109.981 24.0702C108.885 24.2055 107.82 24.5286 106.833 25.0253C106.24 25.2965 105.745 25.745 105.418 26.3091C105.091 26.8731 104.947 27.5247 105.006 28.1741C105.008 28.8237 105.211 29.4569 105.587 29.9868C105.962 30.5167 106.493 30.9174 107.105 31.1339C108.722 31.7217 110.412 32.0995 112.07 32.5613C113.403 32.9287 114.788 33.1701 116.079 33.6109C116.991 33.9096 117.79 34.478 118.371 35.2408C118.952 36.0037 119.287 36.9249 119.333 37.8827C119.489 38.9322 119.31 40.004 118.822 40.9461C118.334 41.8881 117.562 42.6525 116.615 43.1307C113.109 45.1144 107.336 44.5896 104.765 42.0811C104.113 41.4256 103.598 40.6476 103.248 39.792C102.899 38.9363 102.722 38.0198 102.729 37.0956Z'
          fill='#151515'
        />
        <path
          d='M138.226 29.2343H136.83C136.83 29.0454 136.756 28.8774 136.735 28.72C136.713 27.6736 136.331 26.6668 135.655 25.8681C134.979 25.0694 134.048 24.5274 133.02 24.3327C131.08 23.776 129.01 23.8756 127.132 24.6161C126.389 24.829 125.736 25.2761 125.269 25.8909C124.801 26.5057 124.546 27.2551 124.539 28.0273C124.513 28.7856 124.75 29.5297 125.208 30.134C125.667 30.7382 126.321 31.1656 127.058 31.344C129.157 31.9947 131.309 32.3936 133.44 32.9813C134.13 33.147 134.81 33.3538 135.476 33.6006C136.47 33.9563 137.334 34.6036 137.954 35.458C138.575 36.3125 138.923 37.3342 138.954 38.3896C138.984 39.4451 138.696 40.4853 138.126 41.3742C137.556 42.2632 136.731 42.9595 135.759 43.3722C134.278 44.0448 132.673 44.4006 131.046 44.4169C129.42 44.4332 127.807 44.1095 126.313 43.4667C125.084 42.9367 124.047 42.0429 123.342 40.9058C122.636 39.7686 122.296 38.4426 122.367 37.1062H122.954H123.731C123.983 38.324 124.529 39.462 125.32 40.4215C126.112 41.381 127.125 42.1331 128.272 42.6128C129.42 43.0925 130.667 43.2852 131.906 43.1743C133.144 43.0634 134.337 42.6522 135.381 41.9763C136.017 41.6459 136.545 41.14 136.903 40.5186C137.26 39.8973 137.431 39.1865 137.397 38.4707C137.428 37.7658 137.229 37.0698 136.83 36.4883C136.43 35.9068 135.851 35.4715 135.182 35.2484C133.545 34.6397 131.802 34.3143 130.134 33.779C128.387 33.2922 126.679 32.6783 125.022 31.9422C122.398 30.6512 122.188 25.5817 125.179 23.9654C126.757 23.049 128.546 22.5568 130.37 22.5365C132.195 22.5163 133.994 22.9688 135.591 23.8499C136.505 24.3954 137.241 25.1948 137.708 26.1508C138.176 27.1067 138.356 28.178 138.226 29.2343Z'
          fill='#151515'
        />
        <path
          d='M46.7967 43.8235C46.2472 44.0316 45.6405 44.0311 45.0912 43.8223C44.542 43.6134 44.0883 43.2106 43.8159 42.6899C42.1051 40.1289 40.2158 37.6834 38.4 35.1854L37.6128 34.1358C35.4507 37.0327 33.2885 39.8141 31.3153 42.7004C31.0582 43.198 30.6291 43.5852 30.1077 43.7899C29.5864 43.9946 29.0084 44.0028 28.4814 43.813L36.5947 32.9288L29.0482 23.0942C29.5914 22.898 30.1857 22.8953 30.7307 23.0865C31.2757 23.2778 31.738 23.6512 32.0395 24.1437C33.7923 26.7047 35.7341 29.1503 37.6653 31.7428C39.6071 29.1713 41.5278 26.7152 43.3226 24.1752C43.5811 23.6857 44.0105 23.3083 44.5292 23.1148C45.0478 22.9213 45.6195 22.9251 46.1355 23.1257L43.2071 27.0091C41.8951 28.7409 40.6042 30.4832 39.2397 32.1731C39.1298 32.2715 39.0418 32.392 38.9816 32.5268C38.9214 32.6615 38.8903 32.8074 38.8903 32.955C38.8903 33.1026 38.9214 33.2485 38.9816 33.3833C39.0418 33.518 39.1298 33.6385 39.2397 33.737C41.7482 37.0642 44.2357 40.4018 46.7967 43.8235Z'
          fill='#151515'
        />
        <path
          d='M81.2443 22.9893V24.4587C80.1828 24.3891 79.1184 24.5428 78.1199 24.91C77.1215 25.2771 76.2111 25.8496 75.4475 26.5904C74.684 27.3312 74.0843 28.2238 73.6871 29.2107C73.2899 30.1977 73.104 31.2569 73.1415 32.3201C73.1415 35.7767 73.1415 39.2333 73.1415 42.69C73.1415 43.0573 73.1415 43.4247 73.1415 43.8655H71.6406V23.0732H73.11V27.3345C74.9258 24.2278 77.7282 23.0732 81.2443 22.9893Z'
          fill='#151515'
        />
        <path
          d='M9.04297 32.7923C9.26338 31.7427 9.39983 30.5987 9.70421 29.5281C11.52 23.0731 18.9196 20.3862 24.0101 24.3851C26.9909 26.7257 27.7361 30.0424 27.5891 33.7789H10.7958C10.5229 40.4543 15.3405 44.4847 21.5015 42.4275C22.501 42.07 23.3956 41.4693 24.1048 40.6795C24.8139 39.8897 25.3152 38.9358 25.5634 37.9038C25.8888 36.8542 26.4136 36.6758 27.4107 36.9801C27.2465 38.2579 26.7947 39.4818 26.0893 40.5599C25.384 41.6379 24.4434 42.542 23.3383 43.2042C21.5125 44.2026 19.4176 44.5982 17.3534 44.3343C15.2892 44.0704 13.3613 43.1605 11.8454 41.7348C10.3457 40.0486 9.43888 37.918 9.26338 35.6682C9.26338 35.3113 9.12694 34.9544 9.05346 34.6186C9.04647 33.9958 9.04297 33.3871 9.04297 32.7923ZM10.8168 32.341H26.0043C25.9098 27.5024 22.8555 24.0702 18.7831 24.0388C14.2489 23.9758 11.0057 27.3345 10.8063 32.32L10.8168 32.341Z'
          fill='#151515'
        />
        <rect
          width='148'
          height='74'
          fill='#5A32FB'
          style={{
            mixBlendMode: 'color',
          }}
        />
      </g>
      <defs>
        <clipPath id='clip0_352_489'>
          <rect width='148' height='74' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

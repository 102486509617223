import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import palette from "../../../theme/palette";
import theme from "../../../theme";

export const LandingDetailsBlockSecondWrapper = styled(Box)({
  position: "relative",
  "@media (min-width: 1201px) and (min-height: 753px)": {
    marginTop: "95px",
  },
  [theme.breakpoints.down("xl")]: {
    paddingTop: "120px",
  },
});

export const LandingDetailsBlockSecondDescription = styled(Typography)({
  maxWidth: "778px",
  width: "100%",
  marginTop: "24px",
  fontWeight: 400,
  [theme.breakpoints.down("xl")]: {
    marginTop: "16px",
    fontSize: "18px",
    lineHeight: "28px",
    marginBottom: "32px",
  },
});

export const LandingDetailsBlockSecondList = styled(Box)({
  margin: "0px auto",
  padding: "64px 103px 57px 103px",
  maxWidth: "1180px",
  display: "flex",
  justifyContent: "space-between",
  color: palette.secondary,
  backgroundColor: palette.primary,

  "& .container": {
    justifyContent: "space-between",
  },

  [theme.breakpoints.down("xl")]: {
    flexDirection: "column",
    backgroundColor: "transparent",
    padding: "0px 26px",
    marginBottom: "37px",
  },

  [theme.breakpoints.down("md")]: {
    padding: "0px 16px",
  },
});

export const LandingDetailsBlockSecondItem = styled(Box)({
  maxWidth: "420px",
  width: "100%",

  "& .title": {
    letterSpacing: "-0.02em",
  },

  [theme.breakpoints.down("xl")]: {
    backgroundColor: palette.primary,
    padding: "24px 16px 40px 16px",
    maxWidth: "unset",
    marginBottom: "20px",
    border: "1px solid #9A9AAF",

    "& .title": {
      fontSize: "24px",
      lineHeight: "32px",
      marginBottom: "24px",
    },

    p: {
      fontSize: "20px",
      lineHeight: "26px",
    },
  },
});

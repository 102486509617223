import React from "react";
import Typography from "@mui/material/Typography";
import BackgroundTitle from "../../backgroundTitle";
import {
  WhyMalevichContent,
  WhyMalevichImage,
  WhyMalevichWrapper,
} from "./style";
import { HOST_IMAGE_URL } from "../../../../constants";
import Container from "../../../container";
import WhyMalevichItems from "./WhyMalevichitems";

const LandingWhyMalevich = ({ element, activeSlideID }) => {
  return (
    <WhyMalevichWrapper>
      <BackgroundTitle maxWidth="740px">{element.title}</BackgroundTitle>
      <Container direction="column">
        <Typography variant="h2" className="title">
          {element.title}
        </Typography>
        <WhyMalevichContent>
          {element?.items?.map((item, index) => {
            return (
              <WhyMalevichItems
                key={item.id}
                item={item}
                index={index}
                activeSlideID={activeSlideID}
              />
            );
          })}
        </WhyMalevichContent>
        <WhyMalevichImage>
          <img
            src={`${HOST_IMAGE_URL + element?.image?.data?.attributes?.url}`}
            alt={element?.image?.data?.attributes?.alternativeText}
            title={element?.image?.data?.attributes?.caption}
          />
        </WhyMalevichImage>
      </Container>
    </WhyMalevichWrapper>
  );
};

export default LandingWhyMalevich;

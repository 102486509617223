import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import theme from "../../../theme";
import palette from "../../../theme/palette";

export const LandingTopWrapper = styled(Box)({
  display: "flex",
  width: "100%",
});

export const LandingTopInfo = styled(Box)({
  display: "flex",
  flexDirection: "column",
  maxWidth: "583px",
  width: "100%",
  "@media (min-width: 1201px) and (min-height: 753px)": {
    marginLeft: "100px",
  },

  "@media (max-width: 978px)": {
    h1: {
      fontSize: "56px",
      fontWeight: 600,
      lineHeight: "64px",
    },
    h6: {
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: " 28px",
      marginBottom: "24px",
    },
  },

  [theme.breakpoints.down("lg")]: {
    maxWidth: "unset",
  },
});

export const LandingTopDescription = styled(Typography)({
  marginTop: "24px",
});

export const ImgContainer = styled(Box)({
  flexShrink: 0,
  marginLeft: 15,

  "& img": {
    width: 284,
  },

  "@media (max-width: 1148px)": {
    marginLeft: 0,
  },
});

export const HomeTopAnimation = styled(Box)({
  width: 364,
  height: 468,

  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "auto",
    maxWidth: "256px",
  },
});

export const PreviewHeaderBottom = styled(motion.div)({
  height: "100vh",

  svg: {
    height: "100vh",
  },
  "@media (min-width: 1201px) and (min-height: 753px)": {
    height: "130vh",
  },
});

export const ArrowContainer = styled(Box)({
  display: "flex",
  alignItems: "flex-end",
  marginRight: "4px",

  h6: {
    transform: "rotate(-90deg)",
    marginLeft: "-27px",
    marginBottom: "70px",
    fontSize: "24px",
    lineHeight: "36px",
  },

  [theme.breakpoints.down("lg")]: {
    h6: {
      fontSize: "20px",
      lineHeight: "30px",
    },
  },
});

export const LandingTopContent = styled(Box)({
  display: "flex",
  alignItems: "center",
  img: {
    maxWidth: "400px",
  },
  "@media (min-width: 1201px) and (min-height: 753px)": {
    "&.landing-top-content": {
      marginTop: "-130px",
    },
  },
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  [theme.breakpoints.down("md")]: {
    marginLeft: "-40px",

    img: {
      maxWidth: "315px",
      width: "100%",
    },
  },

  "@media (max-width: 375px)": {
    maxWidth: "270px",
  },
});

export const StyledLayout = styled(Box)({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",

  "& .footer-title": {
    textTransform: "uppercase",
  },

  [theme.breakpoints.down("lg")]: {
    "& .footer-title": {
      marginBottom: "-40px",
    },
  },

  "@media (min-width: 1201px) and (min-height: 753px)": {
    "& .slide-background": {
      height: "130vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      overflow: "hidden",
      willChange: "transform",
      WebkitBackfaceVisibility: "hidden",
      backfaceVisibility: "hidden",
      position: "fixed",
      width: "100%",
      WebkitTransform: "translateY(20vh)",
      MsTransform: "translateY(20vh)",
      transform: "translateY(20vh)",
      WebkitTransition: "all 1.4s cubic-bezier(0.22, 0.44, 0, 1)",
      transition: "all 1.4s cubic-bezier(0.22, 0.44, 0, 1)",
      background: palette.secondary,
      boxShadow: "0px 24px 48px -12px rgba(21, 21, 21, 0.18)",

      "&.slide-background-translate": {
        WebkitTransform: "translateY(0vh)",
        MsTransform: "translateY(0vh)",
        transform: "translateY(0vh)",
      },

      "& .footer-title": {
        marginBottom: "-78px",
        marginTop: " 124px",
      },
    },
  },

  "& .background-title": {
    "@media (min-width: 1201px) and (min-height: 753px)": {
      top: "-90px !important",
    },

    "@media (min-width: 1201px) and (min-height: 960px)": {
      top: "-120px !important",
    },
  },
});

export const PreviewHeaderAnimate = styled(motion.div)({
  width: "100%",
  height: "130vh",
  backgroundColor: palette.primary,
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

export const InfoBlockText = styled(motion.div)({
  marginBottom: "24px",
  color: "#ffffff",

  "&:last-child": {
    marginBottom: 0,
  },
  [theme.breakpoints.down("md")]: {
    marginBottom: "8px",
    h2: {
      fontWeight: 300,
      fontSize: "32px",
      lineHeight: "48px",
    },
    h1: {
      fontWeight: 600,
      fontSize: "56px",
      lineHeight: "64px",
    },
  },
});

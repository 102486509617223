import { Typography } from "@mui/material";
import React, { useState } from "react";

import ModalCard from "./ModalCard";
import {
  OurWorkDescriptionWrapper,
  OurWorkItemSubTitle,
  OurWorkItemTechnologiesList,
  OurWorkItemTechnology,
  OurWorkItemText,
  OurWorkItemTitle,
  OurWorkItemWrapper,
  ReadMoreWrapper,
} from "./style";
import PlusInCircle from "../../../../assets/icons/PlusInCircle";
import { HOST_IMAGE_URL } from "../../../../constants";

const OutWorkSliderContent = ({ item }) => {
  const [toggleMore, setToggleMore] = useState(false);

  const handleToggleMore = () => {
    setToggleMore((previous) => !previous);
  };

  return (
    <>
      <OurWorkItemWrapper>
        <img
          src={`${HOST_IMAGE_URL + item?.image?.data?.attributes?.url}`}
          alt={item?.image?.data?.attributes?.alternativeText}
          title={item?.image?.data?.attributes?.caption}
        />
        <OurWorkDescriptionWrapper>
          <div className="ourWorkDescription">
            <div>
              <OurWorkItemTitle variant="h4" component="div">
                {item.title}
              </OurWorkItemTitle>
              {item.subtitle !== "" && (
                <OurWorkItemSubTitle variant="body2" component="div">
                  {item.subtitle}
                </OurWorkItemSubTitle>
              )}
              <OurWorkItemText
                variant="subtitle1"
                component="div"
                className="cutText"
                dangerouslySetInnerHTML={{
                  __html: item.text,
                }}
              />
            </div>
            <OurWorkItemTechnologiesList>
              {item?.technologies?.data?.map((technology) => {
                return (
                  <OurWorkItemTechnology key={technology.id}>
                    <img
                      src={`${
                        HOST_IMAGE_URL +
                        technology?.attributes?.icon?.data?.attributes?.url
                      }`}
                      alt={
                        technology?.attributes?.icon?.data?.attributes
                          ?.alternativeText
                      }
                      title={
                        technology?.attributes?.icon?.data?.attributes?.caption
                      }
                    />
                  </OurWorkItemTechnology>
                );
              })}
            </OurWorkItemTechnologiesList>
          </div>
          <ReadMoreWrapper onClick={handleToggleMore}>
            <Typography variant="body2">Read More</Typography>
            <PlusInCircle />
          </ReadMoreWrapper>
        </OurWorkDescriptionWrapper>
      </OurWorkItemWrapper>
      {toggleMore && (
        <ModalCard close={handleToggleMore} open={toggleMore} item={item} />
      )}
    </>
  );
};

export default OutWorkSliderContent;

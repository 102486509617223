import React, { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Container from "../../container";
import {
  AdvantagesAdaptiveContainer,
  AdvantagesSliderBackground,
  LandingAdvantagesItem,
  LandingAdvantagesItemTop,
  LandingAdvantagesList,
  LandingAdvantagesWrapper,
} from "./style";
import { HOST_IMAGE_URL } from "../../../constants";
import Cube from "../../../assets/icons/Cube";
import Box from "@mui/material/Box";
import useDragList from "../../../hooks/use-drag-list";
import { useBreakpointValue } from "../../../hooks/use-breakpoint-value";
import AdvantagesItemsSlider from "./AdvantagesItemsSlider";

const LandingAdvantages = ({ element }) => {
  const advantagesRef = useDragList();

  const containerRef = useRef(null);
  const [advantagesStyles, setAdvantagesStyles] = useState({});

  const isMobile = useBreakpointValue(["smallMobile", "mobile", "bigMobile"]);

  useEffect(() => {
    if (containerRef.current) {
      setAdvantagesStyles({
        marginLeft: `${containerRef.current.offsetLeft * -1}px`,
        paddingLeft: `${containerRef.current.offsetLeft}px`,
        width: `calc(100% + ${containerRef.current.offsetLeft * 2}px)`,
      });
    }
  }, []);

  return (
    <LandingAdvantagesWrapper ref={containerRef}>
      <Container direction="column">
        <Typography variant="h2">{element.title}</Typography>
        {isMobile ? (
          <AdvantagesAdaptiveContainer>
            <AdvantagesItemsSlider element={element} />
            <AdvantagesSliderBackground />
          </AdvantagesAdaptiveContainer>
        ) : (
          <LandingAdvantagesList ref={advantagesRef} sx={advantagesStyles}>
            {element?.items?.map((item) => {
              return (
                <LandingAdvantagesItem key={item.id}>
                  <LandingAdvantagesItemTop>
                    <img
                      className="icon"
                      src={`${
                        HOST_IMAGE_URL + item?.icon?.data?.attributes?.url
                      }`}
                      alt={item?.icon?.data?.attributes?.alternativeText}
                      title={item?.icon?.data?.attributes?.caption}
                    />
                    <img
                      className="hoverIcon"
                      src={`${
                        HOST_IMAGE_URL + item?.hoverIcon?.data?.attributes?.url
                      }`}
                      alt={item?.hoverIcon?.data?.attributes?.alternativeText}
                      title={item?.hoverIcon?.data?.attributes?.caption}
                    />
                    <Box className="cube">
                      <Cube />
                    </Box>
                  </LandingAdvantagesItemTop>
                  <Typography
                    variant="body2"
                    component="div"
                    className="description"
                  >
                    {item.description}
                  </Typography>
                </LandingAdvantagesItem>
              );
            })}
          </LandingAdvantagesList>
        )}
      </Container>
    </LandingAdvantagesWrapper>
  );
};

export default LandingAdvantages;

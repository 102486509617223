import React from "react";
import Typography from "@mui/material/Typography";
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";
import Container from "../../../container";
import {
  OurWorkContainer,
  OurWorkContent,
  OurWorkSubTitle,
  OurWorkWrapper,
} from "./style";
import BackgroundTitle from "../../backgroundTitle";
import {
  SliderAdaptiveContainer,
  SliderBackground,
} from "../../../slider/styles";
import theme from "../../../../theme";
import OurWorkSlider from "./OurWorkSlider";
import OurWorkItems from "./OurWorkItems";
import { useBreakpointValue } from "../../../../hooks/use-breakpoint-value";

function useParallax(value, distance) {
  return useTransform(value, [0, 1], [-distance, distance]);
}

const OurWork = ({ element }) => {
  const isTablet = useBreakpointValue([
    "smallMobile",
    "mobile",
    "bigMobile",
    "tablet",
  ]);
  const isMobile = useBreakpointValue(["smallMobile", "mobile"]);

  const reference = useRef(null);
  const listInnerReference = useRef(null);

  const { scrollYProgress } = useScroll({
    container: listInnerReference,
    target: reference,
  });

  const y = useParallax(scrollYProgress, isTablet ? 0 : 300);

  const scrollIsDisabled = useRef(true);

  const onWheel = (e) => {
    if (scrollIsDisabled.current) {
      e.stopPropagation();
    }
  };

  const onScroll = () => {
    if (listInnerReference.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        listInnerReference.current;
      if (Math.ceil(scrollTop + clientHeight) === scrollHeight + 1) {
        scrollIsDisabled.current = false;
      } else if (Math.ceil(scrollTop + clientHeight) === scrollHeight) {
        scrollIsDisabled.current = false;
      } else if (scrollTop === 0) {
        scrollIsDisabled.current = false;
      } else {
        scrollIsDisabled.current = true;
      }
    }
  };

  return (
    <OurWorkWrapper
      onWheel={onWheel}
      onScroll={onScroll}
      ref={listInnerReference}
    >
      <BackgroundTitle maxWidth="800px">{element.title}</BackgroundTitle>
      <Container direction="column">
        <Typography variant="h2" className="blockTitle">
          {element.title}
        </Typography>
        <OurWorkSubTitle variant="body1">{element.subtitile}</OurWorkSubTitle>
        {isMobile ? (
          <SliderAdaptiveContainer>
            <OurWorkSlider element={element} />
            <SliderBackground />
          </SliderAdaptiveContainer>
        ) : (
          <OurWorkContainer>
            <OurWorkContent
              ref={reference}
              sx={{
                [theme.breakpoints.down("xl")]: {
                  marginTop: "32px",
                },
              }}
            >
              {element?.leftColumn?.map((item) => {
                return <OurWorkItems item={item} key={item.id} />;
              })}
            </OurWorkContent>
            <motion.div
              style={{
                y,
                margin: isTablet ? "20px 0px 0px 0px" : "300px 0px",
              }}
            >
              <OurWorkContent>
                {element?.rightColumn?.map((item) => {
                  return (
                    <OurWorkItems
                      item={item}
                      key={item.id}
                      sx={{
                        marginTop: "-68px",
                        marginBottom: "94px",
                        [theme.breakpoints.down("xl")]: {
                          "&:last-child": {
                            marginBottom: "26px",
                          },
                        },
                      }}
                    />
                  );
                })}
              </OurWorkContent>
            </motion.div>
          </OurWorkContainer>
        )}
      </Container>
    </OurWorkWrapper>
  );
};

export default OurWork;

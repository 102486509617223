import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import theme from "../../../../theme";

export const TeamBannerWrapper = styled(Box)({
  margin: "80px 0 24px",
  "@media (min-width: 1201px) and (max-height: 853px)": {
    marginTop: "106px",
  },
  [theme.breakpoints.down("xl")]: {
    margin: "64px 0 24px",
  },

  [theme.breakpoints.down("lg")]: {
    margin: "32px 0 0",
  },
});

export const TeamBannerContainer = styled(Box)({
  "& img": {
    width: "100%",
  },

  [theme.breakpoints.down("sm")]: {
    width: "calc(100% + 32px)",
    margin: "0px -16px",
  },
});

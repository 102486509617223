import React from "react";

export const CursorIcon = ({ color = "#5A32FB", width = 23, height = 23 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1104 22.0417C9.83887 22.0417 9.58331 21.9897 9.34373 21.8855C9.10415 21.782 8.89651 21.6424 8.72081 21.4667L5.15102 17.897C4.83158 17.5775 4.65173 17.1782 4.61148 16.699C4.57187 16.2199 4.66387 15.7886 4.88748 15.4053C5.09512 15.0539 5.37879 14.8063 5.73848 14.6626C6.09754 14.5188 6.43679 14.4869 6.75623 14.5667L8.62498 14.998V7.18758C8.62498 6.51675 8.85658 5.94973 9.31977 5.48654C9.78297 5.02335 10.35 4.79175 11.0208 4.79175C11.6916 4.79175 12.2587 5.02335 12.7219 5.48654C13.1851 5.94973 13.4166 6.51675 13.4166 7.18758V11.3084H14.0396C14.1194 11.3084 14.1951 11.3202 14.2667 11.3439C14.3389 11.3682 14.4069 11.4042 14.4708 11.4522L18.0166 13.1772C18.4 13.3529 18.6836 13.6362 18.8676 14.0272C19.051 14.4188 19.1028 14.8223 19.0229 15.2376L18.1604 20.4605C18.0805 20.9237 17.8569 21.3032 17.4896 21.599C17.1222 21.8942 16.7069 22.0417 16.2437 22.0417H10.1104ZM10.1104 20.1251H16.2437L17.1541 14.9022L13.1771 12.9376H11.5V7.18758C11.5 7.04383 11.4562 6.92787 11.3687 6.83971C11.2805 6.75218 11.1646 6.70841 11.0208 6.70841C10.8771 6.70841 10.7611 6.75218 10.6729 6.83971C10.5854 6.92787 10.5416 7.04383 10.5416 7.18758V17.3459L6.46873 16.4834L10.1104 20.1251Z"
        fill={color}
      />
      <path
        d="M2.87484 6.70825C2.60331 6.70825 2.37587 6.61657 2.1925 6.43321C2.0085 6.24921 1.9165 6.02145 1.9165 5.74992V2.63534C1.9165 2.4277 1.98455 2.25584 2.12063 2.11975C2.25607 1.98431 2.42762 1.91659 2.63525 1.91659C2.84289 1.91659 3.01475 1.98431 3.15084 2.11975C3.28628 2.25584 3.354 2.4277 3.354 2.63534V3.83325C4.504 2.89089 5.7738 2.17597 7.16338 1.6885C8.55296 1.20167 9.99845 0.958252 11.4998 0.958252C13.0012 0.958252 14.4467 1.20167 15.8363 1.6885C17.2259 2.17597 18.4957 2.89089 19.6457 3.83325V2.63534C19.6457 2.4277 19.7134 2.25584 19.8488 2.11975C19.9849 1.98431 20.1568 1.91659 20.3644 1.91659C20.5721 1.91659 20.7439 1.98431 20.88 2.11975C21.0154 2.25584 21.0832 2.4277 21.0832 2.63534V5.74992C21.0832 6.02145 20.9912 6.24921 20.8072 6.43321C20.6238 6.61657 20.3964 6.70825 20.1248 6.70825H17.0103C16.8026 6.70825 16.6308 6.64021 16.4947 6.50413C16.3592 6.36868 16.2915 6.19714 16.2915 5.9895C16.2915 5.78186 16.3592 5.61032 16.4947 5.47488C16.6308 5.33879 16.8026 5.27075 17.0103 5.27075H19.0707C18.0165 4.34436 16.8425 3.6336 15.5488 3.13846C14.255 2.64332 12.9054 2.39575 11.4998 2.39575C10.0943 2.39575 8.74463 2.64332 7.45088 3.13846C6.15713 3.6336 4.98317 4.34436 3.929 5.27075H5.98942C6.19706 5.27075 6.3686 5.33879 6.50405 5.47488C6.64013 5.61032 6.70817 5.78186 6.70817 5.9895C6.70817 6.19714 6.64013 6.36868 6.50405 6.50413C6.3686 6.64021 6.19706 6.70825 5.98942 6.70825H2.87484Z"
        fill={color}
      />
    </svg>
  );
};

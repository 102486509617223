import Typography from "@mui/material/Typography";
import React, { useEffect, useRef, useState } from "react";
import useDragList from '../../../../hooks/use-drag-list';
import Container from "../../../container";
import {
    ExpertiseAdaptiveContainer,
    ExpertiseContainer,
    ExpertiseSliderBackground,
    ExpertiseTooltip,
    ExpertiseWrapper
} from "./style";
import Box from "@mui/material/Box";
import LandingExpertiseItems from "./LandingExpertiseItems";
import LandingExpertiseItemsSlider from "./LandingExpertiseItemsSlider";
import { useBreakpointValue } from "../../../../hooks/use-breakpoint-value";

const LandingExpertise = ({ element, categories }) => {
    const isMobile = useBreakpointValue(["smallMobile", "mobile"]);
    const expertiseReference = useDragList();

    const containerReference = useRef(null);
    const tooltipReference = useRef(null);

    const [expertiseStyles, setExpertiseStyles] = useState({});

    const handleMouseMove = (ref) => (e) => {
        const { pageX, pageY } = e;
        if (ref.current) {
            if (!Number.parseInt(ref.current.style.opacity)) {
                ref.current.style.opacity = 1;
            }

            ref.current.style.top = `${pageY + 20}px`;
            ref.current.style.left = `${pageX + 20}px`;
        }
    };
    const handleMouseLeave = (reference) => () => {
        reference.current.style.opacity = 0;
    };

    useEffect(() => {
        if (containerReference.current) {
            setExpertiseStyles({
                marginLeft: `${containerReference.current.offsetLeft * -1}px`,
                paddingLeft: `${containerReference.current.offsetLeft}px`,
                width: `calc(100% + ${containerReference.current.offsetLeft * 2}px)`,
            });
        }
    }, []);

    return (
        <Container ref={containerReference}>
            <Box width='100%'>
                <ExpertiseWrapper>
                    <Typography variant='h2'>{element.title}</Typography>
                    {isMobile ? (
                        <ExpertiseAdaptiveContainer>
                            <LandingExpertiseItemsSlider categories={categories} />
                            <ExpertiseSliderBackground />
                        </ExpertiseAdaptiveContainer>
                    ) : (
                        <ExpertiseContainer
                            onMouseMove={handleMouseMove(tooltipReference)}
                            onMouseLeave={handleMouseLeave(tooltipReference)}
                            ref={expertiseReference}
                            sx={expertiseStyles}
                        >
                            <LandingExpertiseItems categories={categories} />
                        </ExpertiseContainer>
                    )}
                    <ExpertiseTooltip ref={tooltipReference}>Drag</ExpertiseTooltip>
                </ExpertiseWrapper>
            </Box>
        </Container>
    );
};

export default LandingExpertise;

import { useCallback, useEffect, useRef, useState } from "react";

const useDragList = () => {
  const dragListRef = useRef(null);

  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState();
  const [scrollLeft, setScrollLeft] = useState();

  const handleMouseDown = useCallback((e) => {
    if (dragListRef.current) {
      dragListRef.current.classList.add("active");

      setIsDown(true);
      setStartX(e.pageX - dragListRef.current.offsetLeft);
      setScrollLeft(dragListRef.current.scrollLeft);
    }
  }, []);

  const handleMouseLeave = useCallback(() => {
    if (dragListRef.current) {
      dragListRef.current.classList.remove("active");

      setIsDown(false);
    }
  }, []);

  const handleMouseUp = useCallback(() => {
    if (dragListRef.current) {
      dragListRef.current.classList.remove("active");

      setIsDown(false);
    }
  }, []);

  const handleMouseMove = useCallback(
    (e) => {
      e.preventDefault();
      if (!isDown) return;

      if (dragListRef.current) {
        const x = e.pageX - dragListRef.current.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        dragListRef.current.scrollLeft = scrollLeft - walk;
      }
    },
    [isDown, scrollLeft, startX]
  );

  useEffect(() => {
    const ref = dragListRef.current;

    if (dragListRef.current) {
      dragListRef.current.addEventListener("mousedown", handleMouseDown);
      dragListRef.current.addEventListener("mouseleave", handleMouseLeave);
      dragListRef.current.addEventListener("mouseup", handleMouseUp);
      dragListRef.current.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      if (ref) {
        ref.removeEventListener("mousedown", handleMouseDown);
        ref.removeEventListener("mouseleave", handleMouseLeave);
        ref.removeEventListener("mouseup", handleMouseUp);
        ref.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, [handleMouseMove, handleMouseDown, handleMouseLeave, handleMouseUp]);

  return dragListRef;
};

export default useDragList;

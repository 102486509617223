import React from "react";
import Container from "../../../container";
import {
    TechnologyStack,
    TechnologyStackContent,
    TechnologyStackInfo, TechnologyStackItem,
} from "./style";
import Typography from "@mui/material/Typography";
import { ArrowTechnologyStackIcon } from "../../../../assets/icons/ArrowTechnologyStackIcon";
import { CursorIcon } from "../../../../assets/icons/CursorIcon";
import { TECHNOLOGY_STACK_ICONS } from '../../../../MOCK/TechnologyStack';

const LandingTechnologyStack = ({ element }) => {
    return (
        <TechnologyStack>
            <Container direction='column'>
                <Typography variant='h2'>{element.title}</Typography>
                <TechnologyStackContent>
                    {TECHNOLOGY_STACK_ICONS?.map((item) => {
                        return <TechnologyStackItem key={item.id}>{item.icon}</TechnologyStackItem>
                    })}
                </TechnologyStackContent>
                <TechnologyStackInfo>
                    <div className='firstArrowIcon'>
                        <ArrowTechnologyStackIcon />
                    </div>
                    <div className='cursorIcon'>
                        <CursorIcon />
                    </div>
                    <div className='secondArrowIcon'>
                        <ArrowTechnologyStackIcon />
                    </div>
                </TechnologyStackInfo>
            </Container>
        </TechnologyStack>
    );
};

export default LandingTechnologyStack;

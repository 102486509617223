import AnchorLink from "react-anchor-link-smooth-scroll";
import { useBreakpointValue } from "../../../hooks/use-breakpoint-value";
import React from "react";
import Button from "../../button";
import Container from "../../container";
import Logo from "../../logo";
import { HeaderWrapper, StyledHeader } from "./styles";

const LandingHeader = ({ sxWrapper, handleClickHireUs }) => {
  const isMobile = useBreakpointValue([
    "smallMobile",
    "mobile",
    "bigMobile",
    "tablet",
  ]);
  return (
    <HeaderWrapper sx={sxWrapper}>
      <Container>
        <StyledHeader>
          <Logo />
          <AnchorLink href="#footer">
            <Button
              title="Hire us"
              onClick={isMobile ? null : handleClickHireUs}
            />
          </AnchorLink>
        </StyledHeader>
      </Container>
    </HeaderWrapper>
  );
};
export default LandingHeader;

import React, { useRef } from "react";
import {
  InfoBlockText,
  InfoBlockTextWrapper,
  InfoBlockTitle,
  InfoBlockWrapper,
} from "./style";
import Container from "../../../container";
import { useBreakpointValue } from "../../../../hooks/use-breakpoint-value";

const LandingInfoBlock = ({ element, activeSlideID }) => {
  const isTablet = useBreakpointValue([
    "smallMobile",
    "mobile",
    "bigMobile",
    "tablet",
  ]);

  const reference = useRef();

  const WrapperComponentFirst = isTablet
    ? InfoBlockTextWrapper
    : ({ children }) => (
        <InfoBlockText
          initial={{
            y: !isTablet ? 400 : 0,
            opacity: !isTablet ? 0 : 1,
          }}
          animate={{
            ...(activeSlideID === "strapi_ComponentLandingInfoBlock" && {
              y: [400, 200, 200, 0],
              opacity: [0, 0.8, 1, 1],
            }),
          }}
          transition={{
            type: "keyframes",
            times: [0, 0.3, 0.6, 1],
            duration: 2,
          }}
          viewport={{
            once: true,
            amount: 1,
          }}
        >
          {children}
        </InfoBlockText>
      );

  const WrapperComponentSecond = isTablet
    ? InfoBlockTextWrapper
    : ({ children }) => (
        <InfoBlockText
          initial={{
            y: !isTablet ? 400 : 0,
            opacity: !isTablet ? 0 : 1,
          }}
          animate={{
            ...(activeSlideID === "strapi_ComponentLandingInfoBlock" && {
              y: [400, 200, 200, 0],
              opacity: [0, 0.4, 0.6, 1],
            }),
          }}
          transition={{
            type: "keyframes",
            times: [0, 0.3, 0.6, 1],
            duration: 2,
          }}
          viewport={{
            once: true,
            amount: 1,
          }}
        >
          {children}
        </InfoBlockText>
      );

  const WrapperComponentThird = isTablet
    ? InfoBlockTextWrapper
    : ({ children }) => (
        <InfoBlockText
          className="bottomInfoBlockText"
          initial={{
            y: !isTablet ? 400 : 0,
            opacity: !isTablet ? 0 : 1,
          }}
          animate={{
            ...(activeSlideID === "strapi_ComponentLandingInfoBlock" && {
              y: [400, 200, 200, 0],
              opacity: [0, 0.4, 0.6, 1],
            }),
          }}
          transition={{
            type: "keyframes",
            delay: 1,
            times: [0, 0.3, 0.6, 1],
            duration: 2,
          }}
        >
          {children}
        </InfoBlockText>
      );

  return (
    <Container direction="column">
      <InfoBlockWrapper ref={reference}>
        <WrapperComponentFirst>
          <InfoBlockTitle
            dangerouslySetInnerHTML={{
              __html: element?.firstText,
            }}
          />
        </WrapperComponentFirst>
        <WrapperComponentSecond>
          <InfoBlockTitle
            dangerouslySetInnerHTML={{
              __html: element?.secondText,
            }}
          />
        </WrapperComponentSecond>
      </InfoBlockWrapper>
      <InfoBlockWrapper className="bottomText">
        <WrapperComponentThird>
          <InfoBlockTitle
            dangerouslySetInnerHTML={{
              __html: element?.thirdText,
            }}
          />
        </WrapperComponentThird>
      </InfoBlockWrapper>
    </Container>
  );
};

export default LandingInfoBlock;

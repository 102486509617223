import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import React from "react";
import Cube from "../../../assets/icons/Cube";

import { Slider } from "../../slider";
import { LandingAdvantagesItem, LandingAdvantagesItemTop } from "./style";
import { HOST_IMAGE_URL } from "../../../constants";

const AdvantagesItemsSlider = ({ element }) => {
  return (
    <Slider
      settings={{
        autoplay: false,
      }}
      isArrowBox
      width="100%"
      margin="0px"
      wrapperProps={{
        marginBottom: "68px",
      }}
    >
      {element?.items?.map((item) => {
        return (
          <LandingAdvantagesItem key={item.id}>
            <LandingAdvantagesItemTop>
              <img
                className="icon"
                src={`${HOST_IMAGE_URL + item?.icon?.data?.attributes?.url}`}
                alt={item?.icon?.data?.attributes?.alternativeText}
                title={item?.icon?.data?.attributes?.caption}
              />
              <img
                className="hoverIcon"
                src={`${
                  HOST_IMAGE_URL + item?.hoverIcon?.data?.attributes?.url
                }`}
                alt={item?.hoverIcon?.data?.attributes?.alternativeText}
                title={item?.hoverIcon?.data?.attributes?.caption}
              />
              <Box className="cube">
                <Cube />
              </Box>
            </LandingAdvantagesItemTop>
            <Typography variant="body2" component="div" className="description">
              {item.description}
            </Typography>
          </LandingAdvantagesItem>
        );
      })}
    </Slider>
  );
};

export default AdvantagesItemsSlider;

import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import palette from "../../../theme/palette";
import theme from "../../../theme";

export const LandingAdvantagesWrapper = styled(Box)({
  [theme.breakpoints.down("lg")]: {
    h2: {
      fontSize: "32px",
      lineHeight: "48px",
    },
  },
});

export const LandingAdvantagesItem = styled(Box)({
  marginRight: "44px",
  display: "inline-block",
  float: "left",
  flexShrink: 0,
  backgroundColor: palette.secondary,

  width: "364px",
  padding: "26px",
  border: `1px solid ${palette.amethystSmoke}`,

  "& .description": {
    paddingTop: "28px",
    borderTop: `1px solid ${palette.amethystSmoke}`,
    whiteSpace: "break-spaces",
  },

  "& .hoverIcon": {
    display: "none",
  },

  "&:hover": {
    color: palette.secondary,
    background: palette.primary,

    "& .icon": {
      display: "none",
    },

    "& .hoverIcon": {
      display: "flex",
    },

    "& .cube": {
      "& svg path": {
        fill: palette.purpleBlue,
      },
    },
  },

  [theme.breakpoints.down("xl")]: {
    maxWidth: "49%",
    pointerEvents: "none",
  },

  [theme.breakpoints.down("lg")]: {
    maxWidth: "100%",
  },

  [theme.breakpoints.down("md")]: {
    maxWidth: "unset",
    height: "100%",
    backgroundColor: palette.secondary,
  },

  "@media (max-width: 375px)": {
    padding: 16,
  },
});

export const LandingAdvantagesItemTop = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "26px",
});

export const LandingAdvantagesList = styled(Box)({
  display: "flex",
  marginTop: 60,
  marginRight: "auto",
  position: "relative",
  overflowX: "scroll",
  overflowY: "hidden",
  whiteSpace: "nowrap",
  transition: "all 0.2s",
  willChange: "transform",
  userSelect: "none",
  cursor: "move",
  scrollbarColor: "transparent transparent",
  scrollbarWidth: "thin",
  marginBottom: "40px",

  "&::-webkit-scrollbar": {
    width: "3px",
    height: "3px",
  },

  "&::-webkit-scrollbar-thumb": {
    background: "transparent",
  },

  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },

  "& > .active": {
    cursor: "grabbing",
  },

  [theme.breakpoints.down("lg")]: {
    marginTop: 26,
  },
});

export const AdvantagesTooltip = styled("span")({
  display: "inline-block",
  position: "absolute",
  padding: ".5em 1em",
  backgroundColor: palette.mediumBlack,
  borderRadius: 100,
  color: palette.secondary,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "21px",
});

export const AdvantagesAdaptiveContainer = styled(Box)({
  overflow: "hidden",
  display: "flex",
  position: "relative",
  marginRight: "-16px",
  paddingRight: "16px",

  [theme.breakpoints.down("lg")]: {
    margin: "26px 0px 40px 0px",
  },
  "@media(min-width: 902px)": {
    display: "none",
  },
});

export const AdvantagesSliderBackground = styled("div")({
  position: "absolute",
  top: "90px",
  right: 0,
  width: "252px",
  height: "100%",
  background: palette.purpleBlue,
  zIndex: -1,

  "@media(max-width: 481px)": {
    "&.outcome": {
      minHeight: "480px",
    },
  },
});

export const Content = styled(Box)({
  display: "inline-block",
  flexShrink: 0,
  float: "left",
  maxWidth: "364px",
  width: "100%",
  marginRight: "44px",
  padding: "24px",
  color: palette.primary,
  backgroundColor: palette.secondary,
  border: `1px solid ${palette.amethystSmoke}`,

  "&:hover": {
    backgroundColor: palette.primary,
    color: "#ffffff",

    "& svg path": {
      fill: palette.purpleBlue,
    },

    "& .headerContent": {
      borderBottom: `1px solid ${palette.secondary}`,
    },
  },

  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
    height: "100%",
    color: palette.secondary,
    backgroundColor: palette.primary,

    "& svg path": {
      fill: palette.purpleBlue,
    },

    "& .headerContent": {
      borderBottom: `1px solid ${palette.secondary}`,
    },
  },

  [theme.breakpoints.down("xs")]: {
    padding: "16px",
    fontSize: "14px",
    lineHeight: "20px",

    "& .advantagesItemTitle": {
      marginBottom: "16px",
      paddingBottom: "16px",
    },
  },
});

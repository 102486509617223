import React from 'react';

import { ExpertiseContent, ExpertiseDescriptionList, ExpertiseHeaderContent, ExpertiseTitle } from "./style";
import { HOST_IMAGE_URL } from "../../../../constants";

const LandingExpertiseItems = ({ categories }) => {
    return (
        <>
            {categories?.data?.map((category) => {
                return (
                    <ExpertiseContent key={category.id}>
                        <ExpertiseHeaderContent className='headerContent'>
                            <ExpertiseTitle variant='body3'>{category.attributes.preview.title}</ExpertiseTitle>
                            <img
                                className="image"
                                src={`${
                                    HOST_IMAGE_URL +
                                    category?.attributes?.preview?.icon?.data?.attributes?.url
                                }`}
                                alt={
                                    category?.attributes?.preview?.icon?.data?.attributes
                                        ?.alternativeText
                                }
                                title={
                                    category?.attributes?.preview?.icon?.data?.attributes
                                        ?.caption
                                }
                            />
                            <img
                                className="hoverImage"
                                src={`${
                                    HOST_IMAGE_URL +
                                    category?.attributes?.preview?.hoverIcon?.data?.attributes
                                        ?.url
                                }`}
                                alt={
                                    category?.attributes?.preview?.hoverIcon?.data?.attributes
                                        ?.alternativeText
                                }
                                title={
                                    category?.attributes?.preview?.hoverIcon?.data?.attributes
                                        ?.caption
                                }
                            />
                        </ExpertiseHeaderContent>
                        <ExpertiseDescriptionList
                            dangerouslySetInnerHTML={{
                                __html: category?.attributes?.preview?.description,
                            }}
                        />
                    </ExpertiseContent>
                );
            })}
        </>
    );
};

export default LandingExpertiseItems;

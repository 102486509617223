import React from "react";
import Typography from "@mui/material/Typography";
import Container from "../../container";
import {
  LandingDetailsBlockSecondDescription,
  LandingDetailsBlockSecondItem,
  LandingDetailsBlockSecondList,
  LandingDetailsBlockSecondWrapper,
} from "./style";
import BackgroundTitle from "../backgroundTitle";
import Box from "@mui/material/Box";
import ManThinkIcon from "../../../assets/icons/ManThinkIcon";
import { useBreakpointValue } from "../../../hooks/use-breakpoint-value";

const LandingDetailsBlockSecond = ({ element }) => {
  const isTablet = useBreakpointValue([
    "smallMobile",
    "mobile",
    "bigMobile",
    "tablet",
  ]);

  return (
    <LandingDetailsBlockSecondWrapper>
      <BackgroundTitle maxWidth="800px" className="background-title">
        {element.title}
      </BackgroundTitle>
      <Container justifyContent="space-between">
        <Box>
          <Typography variant="h2">{element.title}</Typography>
          <LandingDetailsBlockSecondDescription variant="h4" component="div">
            {element.description}
          </LandingDetailsBlockSecondDescription>
        </Box>
        {!isTablet && <ManThinkIcon />}
      </Container>
      <LandingDetailsBlockSecondList>
        {element?.items?.map((item) => {
          return (
            <LandingDetailsBlockSecondItem key={item.id}>
              <Typography variant="h3" component="div" className="title">
                {item.title}
              </Typography>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              />
            </LandingDetailsBlockSecondItem>
          );
        })}
      </LandingDetailsBlockSecondList>
    </LandingDetailsBlockSecondWrapper>
  );
};

export default LandingDetailsBlockSecond;
